import React from "react";
import Form from "react-bootstrap/Form";
import {titleCase} from "../../Users/addUser";
import TextField from "../../Utils/FormInputs/TextField";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import styled from "@emotion/styled/macro";
import {StyledRemoveIcon, StyledCell} from "../doctorStyles";
import {AutoCompleteProducts} from "../../Utils/FormInputs/AutoCompleteInput";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "@material-ui/lab/Alert";
import { useVersionsContext } from "../../../Context/versions-context";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";


const headData = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true},
    {item:'Quantity to bill',  toolTip:'Enter quantity to bill', isRequired: true, tier:"Premium"},
    {item:'Quantity Prescribed',  toolTip:'Enter Prescribed Quantity'}, {item:'Available Stock'},
    {item:'Dosage',  toolTip:'Enter dosage'}, {item:'Frequency',  toolTip:'Enter frequency'},
    {item:'No. of Days',  toolTip:'Enter number of days'},
    {item:'Instructions',  toolTip:'Enter instructions'}, {item:'Cost'},{item:'Action'}]

    const headDataStandard = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true},
    {item:'Quantity Prescribed',  toolTip:'Enter Prescribed Quantity'}, {item:'Available Stock'},
    {item:'Dosage',  toolTip:'Enter dosage'}, {item:'Frequency',  toolTip:'Enter frequency'},
    {item:'No. of Days',  toolTip:'Enter number of days'},
    {item:'Instructions',  toolTip:'Enter instructions'}, {item:'Cost'},{item:'Action'}]


export const CommonTableData = ({handleChangeItems, removeRow, item, index, isUUID=false}) =>{
    return(
        <>
            <DataCell variant='150'>
                <TextField value={item.dosage} disabled={item.isExpired} name="dosage" data-testid="doc-dosage"
                           onChange={(e) => handleChangeItems(e, index)} type="text"
                           id="dosage"/>
            </DataCell>
            <DataCell variant='150'>
                <TextField value={item.frequency} disabled={item.isExpired} name="frequency"
                           data-testid="doc-frequency"
                           onChange={(e) => handleChangeItems(e, index)}
                           type="text"

                />
            </DataCell>
            <DataCell variant='200'>
                <TextField type="number" value={item.number_of_days} disabled={item.isExpired} name="number_of_days"
                           data-testid="doc-no-of-days"
                           onChange={(e) => handleChangeItems(e, index)}
                           id="number_of_days"
                />
            </DataCell>
            <DataCell variant='200'>
                <TextField value={item.key_information} disabled={item.isExpired} name="key_information"
                           onChange={(e) => handleChangeItems(e, index)} type="text"
                           data-testid="doc-instructions"
                           id="instructions"
                />
            </DataCell>
            <DataCell variant='200'>
                <InputGroup>
                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{item.currency_symbol}</InputGroup.Text>
                    <TextField  type="number" value={item.rate} style={{pointerEvents:'none'}}/>
                </InputGroup>
            </DataCell>
            <DataCell>
                <Tooltip title='Remove'>
                    <IconButton type='button' onClick={
                        isUUID === true ? () => removeRow(item.uuid, item) :
                        () => removeRow(index, item)
                        }>
                        <StyledRemoveIcon/>
                    </IconButton>
                </Tooltip>
            </DataCell>
        </>
    )
}

const StyledContainer = styled(RightAlignedContainer)({
    marginTop:'20px'
})

export const SundriesData = ({ retrieveSundries, clickSundries, handleAddSundry,
                          handleRemoveSundry,  submitted,sundries, sundriesData=[], isEdit}) => {
    return(
        <>
            {sundries.map((item, index) => (
                <TableRow>
                    <DataCell variant='300'>
                        {isEdit && item.prescription_detial_id ? item.product_name :
                            <TextField
                                value={item.product_name ? titleCase(item.product_name) : item.product_name}
                                type="text"
                                name="product_name" data-testid="product_name"
                                // style={{color: item.status === 0 && '#c4495c'}}
                                onChange={(e) => retrieveSundries(e, index)}
                            />}
                        {(submitted && !item.product_name) &&
                            <ErrorMessage>This field is required</ErrorMessage>}

                        {item.showDrug &&
                            <Form.Control as="select" className="drug-ul prescription-ul" multiple>
                                {sundriesData.map((val, idx) => (

                                    <option key={idx} value={val.value} className="drug-li"
                                            onClick={() => clickSundries({
                                                value: val.value,
                                                label: val.label
                                            }, index)}>{val.label}</option>

                                ))}

                            </Form.Control>}
                    </DataCell>
                    <DataCell>
                        <InputGroup>
                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{item.currency_symbol}</InputGroup.Text>
                            <TextField  type="number" value={item.rate} style={{pointerEvents:'none'}}/>
                        </InputGroup>
                    </DataCell>
                    <DataCell>
                        <Tooltip title='Remove'>
                            <IconButton onClick={() => handleRemoveSundry(index, item)}>
                                <StyledRemoveIcon/>
                            </IconButton>
                        </Tooltip>

                    </DataCell>

                </TableRow>))}
            {/*{!isOutPatient ?*/}
                <TableRow>
                    <DataCell colSpan={6}>
                        <button type="button" onClick={handleAddSundry} className="btn btn-sm sms-gray-btn"
                                id="add-row">Add another line
                        </button>
                    </DataCell>
                </TableRow>
            {/*: null}*/}



        </>
    )
}


const NewPrescriptionTable = ({prescription, retrieveDrugs, clickDrug, handleChangeItems, handleAddRow,isOutPatient,
                                  removeRow, handleChangeOther, other, others, handleChangeOtherMedicine,   paid, handleDrugCloseAlert,
                                  handleAddOther, handleRemoveOther, submitted, drugs, isEdit, sundryProps, handleToggleSundries,
                                  isSundries, isResolved, alert,handleCloseAlert,verifyMedicine, handlePrescribe, cancelPrescribed }) =>{
    const all_drugs = drugs ? drugs : [];
    const sundriesHeadData = [ {item:' Sundry Name'}, {item:'Cost'},{item:'Action'} ];
    const isUUID = true;
    const {subscription} = useVersionsContext()
    const version = subscription?.version

    // const alertDrugs = prescription.filter(item=>item.alert.isExpired)
    // const showAlert = prescription.some(item=>item.alert.isExpired)
    //
    // const alertComponent = (
    //     showAlert ? alertDrugs.map((item, idx)=>{
    //         if (item.alert.isExpired === true){
    //             return(
    //                 <Alert key={idx} severity={item.alert.severity} style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}} onClose={()=>handleDrugCloseAlert(idx)}>
    //                     {item.alert.message}
    //                 </Alert>
    //             )
    //         }
    //
    //     }) : null
    // )

    const {open, isExpired, message} = alert
    return (
        <>
            <CustomDialog open={verifyMedicine} handleClose={cancelPrescribed} title='Verify Medicine'>
                <p style={{marginLeft:0, marginRight:0}}>This medicine has already been prescribed to the patient. Would you like to proceed with prescribing it again?</p>
                <RightAlignedContainer>
                    <button onClick={handlePrescribe}  className='btn sms-btn btn-sm mr-3'>Yes</button>
                    <button  onClick={cancelPrescribed} className='btn sms-gray-btn btn-sm'>No</button>
                </RightAlignedContainer>
            </CustomDialog>

            {open && isExpired ? <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}} onClose={handleCloseAlert}>
                {/*<AlertTitle>Expired Drug</AlertTitle>*/}
                <strong>{message} is expired!</strong> Please choose another drug
            </Alert>: null}
            {/*{alertComponent}*/}
            <ModuleTable headData={version== 'Premium' ? headData:headDataStandard }>
                {prescription?.map((item, index) => (
                    <TableRow
                        key={item.uuid}
                    >
                        <DataCell variant='300'>
                            {isEdit && item.prescription_detial_id ? item.drug_name:
                                <>
                                    <AutoCompleteProducts reset={isResolved} options={all_drugs} submitted={submitted} idx={index} handleChange={retrieveDrugs} label_text="label"
                                    medicine_value={{label:prescription[index].drug_name, value: prescription[index].product_id, generic_name: prescription[index].generic_name}}
                                      id="drug_name" value_text="value"
                                    errorMsg_Valid={"Enter valid drug name"} valid_error_check={item.isError && submitted} testId={`doc_drug_name-${index}`}
                                    handleProductData={clickDrug} placeholder="Type Product Name" errorMsg="Enter Product Name"/>
                                </>
                            }
                        </DataCell>
                        {version == "Premium" ? <DataCell variant='150'>
                            {paid ? item.quantity_to_bill :
                                <>
                                    <TextField type="number" disabled={!(item.is_provided && item.pack_unit)} value={item.quantity_to_bill} submitted={submitted && item.is_provided && item.pack_unit}
                                               onChange={(e) => handleChangeItems(e, index)} name="quantity_to_bill" required min={0} />
                                    {submitted && item.is_provided && item.pack_unit && !item.quantity_to_bill ? <ErrorMessage>Quantity to bill is required</ErrorMessage> :  null}
                                </>}
                        </DataCell>: null}
                        <DataCell variant='150'>
                            {paid ? item.quantity_prescribed :
                                <>
                                    <TextField type="number" disabled={item.isExpired} value={item.quantity} submitted={submitted}
                                               onChange={(e) => handleChangeItems(e, index)} name="quantity" required min={0} />
                                    {submitted && !item.quantity  ? <ErrorMessage>Quantity is required</ErrorMessage> :
                                        item.quantity > item.stock ?  <ErrorMessage>Quantity should be less than stock</ErrorMessage> :
                                            item.quantity < 0 ? <ErrorMessage>Quantity should be more than 0</ErrorMessage>:  null}
                                </>}
                        </DataCell>
                        <DataCell variant='100'>
                            {item.stock}
                        </DataCell>
                        <CommonTableData {...{handleChangeItems, removeRow, item, index, isUUID}}/>
                    </TableRow>
                ))}
                {/*{!isOutPatient ?*/}
                    <TableRow>
                        <StyledCell colSpan={headData.length}>
                            <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                    id="add-row">Add another line
                            </button>
                        </StyledCell>
                    </TableRow>
                {/*: null}*/}
                <TableRow>
                    <DataCell>

                        <Form.Check label="Add other drugs" type="checkbox" className="radio-btn mr-4" name="other" inline
                                    onChange={handleChangeOther} checked={other}/>
                        {!other ?  <Form.Check type="checkbox"  label='Request sundries' className="radio-btn" style={{fontSize:'14px'}}
                                               defaultChecked={isSundries} onChange={handleToggleSundries} inline/> : null}

                    </DataCell>
                </TableRow>
                {other ?
                    <>
                        {others.map((item, index) => (
                            <TableRow
                                key={index}
                            >
                                <DataCell>
                                    <TextField
                                        value={item.other_medicine ? titleCase(item.other_medicine) : item.other_medicine}
                                        type="text"
                                        name="other_medicine" data-testid="doc-product-name"
                                        onChange={(e) => handleChangeOtherMedicine(e, index)}
                                    />
                                    {submitted && !item.other_medicine && <ErrorMessage>Quantity is required</ErrorMessage> }
                                </DataCell>
                                <DataCell>
                                <TextField type="number" value={item.quantity}
                                    onChange={(e) => handleChangeOtherMedicine(e, index)} name="quantity"/>
                                {/*submitted && !item.quantity && <ErrorMessage>Quantity is required</ErrorMessage> */}
                                </DataCell>
                                <DataCell/>
                                <CommonTableData {...{handleChangeItems:handleChangeOtherMedicine, removeRow:handleRemoveOther, item, index}}/>
                            </TableRow>
                        ))}
                        {/*{!isOutPatient ?*/}
                            <TableRow>
                            <DataCell colSpan={headData.length}>
                                <button type="button" onClick={handleAddOther} className="btn btn-sm sms-gray-btn"
                                        id="add-row">Add another line
                                </button>
                            </DataCell>
                        </TableRow>
                        {/*:null}*/}
                    </>:null}
                <TableRow>
                    <DataCell>

                        {other ?  <Form.Check type="checkbox"  label='Request sundries' className="radio-btn" style={{fontSize:'14px'}}
                                              defaultChecked={isSundries} onChange={handleToggleSundries} inline/> : null}
                    </DataCell>
                </TableRow>
            </ModuleTable>
            <div className="mt-3">
                {isSundries ?
                    <ModuleTable headData={sundriesHeadData}>
                        <SundriesData {...{...sundryProps, isOutPatient , isEdit}} submitted={submitted} />
                    </ModuleTable>:null}
            </div>

        </>
    )
}

export {NewPrescriptionTable, StyledContainer}


