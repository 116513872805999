import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {convertGmtTime, dateConvert, formatDateTime} from "../Utils/ConvertDate";
import {exportPDF} from "../Utils/downloadPDF";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {history} from "../../Helpers/history";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {DataExportButton} from "../Utils/Buttons/DataExportationButton";
import {DateTimeFilter} from "../Utils/ReusableComponents/DateTimeFilter";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import EditLabResults from "./EditResults";
import {usePrint} from "../Utils/Templates/usePrint";
import {PendingStatus, FinishedStatus, ExpiredStatus} from "../DoctorsModule/doctorStyles";
import axios from "axios";
import {config} from "../../Helpers/env";
import {ResultsReportComponent} from "./ResultsReportComponent";
import {ApproveLabResults} from "./ApproveLabResults";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL', hidden: false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden: false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden: false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden: false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden: false},
    {id: 'hospital_test_name', numeric: false, disablePadding: false, label: 'Lab Test', hidden: false},
    {id: 'sample_id', numeric: false, disablePadding: false, label: 'Sample No.', hidden: false},
    {id: 'result_status', numeric: false, disablePadding: false, label: 'Status', hidden: false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden: false},
];


export const LabResultsList = ({actions, snackbars}) => {


    const csvRef = useRef();
    const [openDialog, setOpenDialog] = useState(false)
    const [ids, setIds] = useState({})
    const [searchValue, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState({requests: [], total_count: 0, loading: 'idle'})
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [state, setState] = useState({start_date: dateConvert(), end_date: dateConvert()})
    const [allRecords, setAllRecords] = useState([]);
    const [viewResults, setViewResults] = useState(false)
    const [isApproved, setIsApproved] = useState('idle')
    const [openApproval, setOpenApproval] = useState(false)

    const {start_date, end_date} = state

    const isResolved = isSubmitted === 'resolved'
    const resultsApproved = isApproved === 'resolved'

    const getAllResults = useCallback(() => {
        (async () => {
            setData({...data, loading: 'pending'})
            try {
                const formData = new FormData();
                formData.append('search', searchValue);
                formData.append('start_date', state.start_date);
                formData.append('end_date', state.end_date);
                formData.append('start', activePage);
                const res = await axios.post(`${config.smsUrl}/claboratory/retrieve_all_lab_results`, formData);

                const response = res.data ?? {}
                const arr = response?.data ?? [];
                const count = response?.iTotalRecords ?? 0;
                const list = arr.map((item, index) => {
                    const count = ((activePage - 1) * 10) + index + 1;
                    const resultStatus = {
                        3: (<PendingStatus>Pending Approval</PendingStatus>),
                        6: (<PendingStatus>Pending Approval</PendingStatus>),
                        8: (<FinishedStatus>Approved</FinishedStatus>),
                        9: (<ExpiredStatus>Canceled</ExpiredStatus>)
                    }

                    const buttons = {
                        3: (
                            <>
                                <button onClick={() => {
                                    setIds(item)
                                    setOpenApproval(true)
                                }}
                                        type="button" className="btn btn-sm sms-amber-btn mr-3 mb-2">Approve
                                </button>
                                <button onClick={() => {
                                    setIds(item)
                                    setViewResults(true)
                                }}
                                        type="button" className="btn btn-sm sms-gray-btn mb-2">View
                                </button>
                            </>

                        ),
                        6: (
                            <>
                                <button onClick={() => {
                                    setIds(item)
                                    setOpenApproval(true)
                                }}
                                        type="button" className="btn btn-sm sms-amber-btn mr-3 mb-2">Approve
                                </button>
                                <button onClick={() => {
                                    setIds(item)
                                    setViewResults(true)
                                }}
                                        type="button" className="btn btn-sm sms-gray-btn mb-2">View
                                </button>
                            </>

                        ),
                        8: (
                            <button onClick={() => {
                                setIds(item)
                                setViewResults(true)
                            }}
                                    type="button" className="btn btn-sm sms-gray-btn mr-3 mb-2">View</button>
                        ),
                        // 9: (
                        //     <>
                        //         <button onClick={() => () => {
                        //             setIds(item)
                        //             setViewResults(true)
                        //         }}
                        //                 type="button" className="btn btn-sm sms-gray-btn mr-3 mb-2">View
                        //         </button>
                        //         <button onClick={() => {
                        //             setIds(item)
                        //             setOpenDialog(true)
                        //         }} type="button" className="btn btn-sm sms-info-btn mb-2">Edit
                        //         </button>
                        //     </>
                        // )
                    }
                    return {
                        ...item,
                        count,
                        date: item.date_created ? formatDateTime(item.date_created) : "",
                        patient_name: `${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
                        action: buttons[item.status],
                        result_status: resultStatus[item.status]
                    }
                })
                setData({...data, requests: list, loading: 'success', total_count: count})
            } catch (error) {
                setData({...data, loading: 'error'})
                logoutErrorMessage(error, null, actions);
            }
        })()
    }, [activePage, state.start_date, state.end_date, searchValue, isResolved, resultsApproved])

    useLayoutEffect(() => {
        getAllResults();
        const interval = setInterval(() => {
            getAllResults();
        }, 10000);

        return () => clearInterval(interval);
    }, [getAllResults]);


    const handleChange = (event) => {
        const {value, name} = event.target
        setState({...state, [name]: value})
    }
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const retrieveAllRecords = () => {
        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('start_date', state.start_date);
        formData.append('end_date', state.end_date);
        formData.append('start', activePage);
        return axios.post(`${config.smsUrl}/claboratory/retrieve_all_lab_results`, formData)
    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const response = res.data;
            const dt = !response.data ? [] : response.data;
            const arr = dt.map(item => ({
                time_taken: convertGmtTime(item.time_taken),
                patient_name: `${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
                hospital_test_name: item.hospital_test_name,
                sample_id: item.sample_id
            }))
            setAllRecords(arr);
            csvRef.current.link.click()
        }).catch((err) => {
            errorMessages(err, null, actions);
        })
    }

    const downloadPdf = () => {
        retrieveAllRecords().then(res => {
            const response = res.data;
            const dt = !response.data ? [] : response.data;
            const itemList = dt.map(item => ({
                time_taken: convertGmtTime(item.time_taken),
                patient_name: `${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
                hospital_test_name: item.hospital_test_name,
                sample_id: item.sample_id
            }))
            exportPDF("Lab Test Report", [["SL", "Date", "Patient Name",
                    "Lab Test", "Sample No."]],
                itemList.map((item, index) => [index += 1, item.time_taken,
                    item.patient_name, item.hospital_test_name, item.sample_id
                ]),
                "Lab Test Report.pdf");
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

    const headers = [{label: 'Date', key: 'time_taken'}, {label: 'Patient Name', key: 'patient_name'},
        {label: 'Lab Test', key: 'hospital_test_name'}, {label: 'Sample No.', key: 'sample_id'}]

    const {openBar, type, message} = snackbars;


    const components = [{label: 'Pending Lab Requests', path: "/labrequests"},
        {label: 'Pending Lab Results', path: "/pendingsamples"}, {label: ' Lab Results', path: "/labresultlist"},
        {label: 'Lab Reports', path: "/labreports"}]


    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const {componentRef, handlePrint, fontSize} = usePrint(`Lab Results`);
    // const {handleEdit} = printOutProps

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={data.total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const isLoading = data.loading === 'pending';
    const isSuccess = data.loading === 'success';
    const isError = data.loading === 'error';
    return (
        <div className='journals'>
            <PageTitle title='Lab Results'/>
            <SubHeader title="Laboratory" subTitle='Lab Results'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <EditLabResults {...{openDialog, setOpenDialog, ids, actions, isSubmitted, setIsSubmitted}}/>
            <ApproveLabResults {...{actions, ids, setOpenDialog, openApproval, setOpenApproval, setIsApproved}}/>
            <CustomDialog title='View Results' open={viewResults} handleClose={() => setViewResults(false)}
                          maxWidth="md" isContentOverflow={false}>
                <div className="text-right mb-2">
                    <button onClick={handlePrint} className="btn sms-amber-btn mr-3 btn-sm">Print</button>
                </div>
                <div ref={componentRef}>
                    {fontSize}
                    <ResultsReportComponent {...{test_results_id: ids.test_results_id, actions}} />
                </div>
            </CustomDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables'>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <div className="row">
                    <div className="col-md-10 mb-1">
                        <DateTimeFilter {...{
                            start_date,
                            end_date,
                            handleChange,

                        }}/>
                    </div>
                    <div className="col-md-2 mb-1">
                        <DataExportButton data={allRecords} title='Lab Results List' csvRef={csvRef}
                                          exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                    </div>
                </div>
                <CustomTable title='Lab Results' headData={headCells} handler={handleSearch}
                             total_count={data.total_count}
                             records={10}  {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             data={data.requests} colSpan={headCells.length}
                             term={searchValue} pagination={pagination}
                             activePage={activePage}
                             id="searchValue">

                    <TableBody>
                        {isLoading ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                                      alt="loader"/></TableCell>
                        </TableRow> : null}
                        {isSuccess ? data.requests.length > 0 ?
                            data.requests.slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.sl}
                                            className='mui-table-row'
                                            id="services"
                                        >
                                            {headCells.map((head) => (
                                                <TableCell id={head.id} key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }) : <TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow> : null}
                        {isError ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a valid
                                response</TableCell>
                        </TableRow> : null}
                    </TableBody>

                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabResultsList);
