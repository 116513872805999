import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import { convertPhone } from "../../../Utils/convertPhone";
import { useAddAssign } from "../../../BedManager/CustomHooks/useAddAssign";
import {useAddPrescription} from "../../../DoctorsModule/Prescription/hooks/useAddPrescription";
import {titleCase} from "../../../Users/addUser";

export const useAddAdmission = (actions, patient_number, nurseAdmission=false, match) =>{
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;

    const visit_id = match?.visit_id

    const storage = JSON.parse(sessionStorage.getItem(`${user_roles_id}_${visit_id}`))
    const item = storage ? storage : {}
    const note_item = item[visit_id] ? item[visit_id] : {}

    const guardian_name = note_item['first_name'] ? note_item['first_name'] : ''
    const guardian_address = note_item['address'] ? note_item['address']  : ''
    const guardian_contact = note_item['contact'] ? note_item['contact']  : ''
    const guardian_relationship = note_item['relationship'] ? note_item['relationship']  : ''
    const admission_notes = note_item['notes'] ? note_item['notes']  : ''


    const [state, setState] = useState({admission_date: dateConvert(),
        first_name: guardian_name, last_name: "",  address: guardian_address, nin: ''
    });
    const [p_number, setPatientNumber] = useState(patient_number ? patient_number : '');
    const [showNumber, setShowNumber] = useState(false);
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [printDialog, setOpenPrintDialog] = useState(false);
    const [ids, setIds] = useState({ visit_id: match.visit_id, patient_name:''});
    const [patient_admission_id, setAdmissionId] = useState('')
    const [patient_name, setPatientName] = useState('');
    const [contact, setContact] = useState(guardian_contact)
    const [relationship, setRelationship] = useState(guardian_relationship)
    const {assignProps, openDialog, setOpenDialog} = useAddAssign(actions, {...ids, patient_admission_id}, true)
    const [search_parameter, setSearchParameter] = useState('patient_no')
    const [provider_id, setProviderId] = useState('')
    const [isProvider, setIsProvider] = useState(null)
    const [providerDetails, setProviderDetails] = useState({account_name:'', card_number:'', provider_type:''})
    const [providers, setProviders] = useState([])
    const [isOnInsurance, setIsOnInsurance] = useState(false)
    const [addReferral, setAddReferral] = useState(false)
    const [openNewInsurance, setOpenNewInsurance] = useState(false)
    const [new_insurance, setNewInsurance] = useState('')
    const [newProviderDetails, setNewProviderDetails] = useState({account_name:'', card_number:''})
    const [submitProvider, setSubmitProvider] =useState(false)
    const [referralDialog, setReferralDialog] = useState(false);
    const [referrals, setReferrals] = useState({
        referral_date: dateConvert(), referral_number: "",
        visit_date: dateConvert(), diagnosis: "", reason: "", notes: "",
        from_hospital: "", patient_history: "", treatment: ""
    });
 
    const [openPrint, setOpenPrint] = useState(false);

    const [company_name,setCompanyName]= useState(null);
    const [departments, setDepartments] = useState([]);
    const [insurance_status, setInsuranceStatus] = useState('')
    const [insurance_scheme, setInsuranceScheme] = useState([])
    const [insuranceSchemes, setInsuranceSchemes] = useState([])
    const [insuranceDetails, setInsuranceDetails] = useState({})
    const [openAssignBed, setOpenAssignBed] = useState(false)



    const [findings, setAdmissionNotes] = useState(admission_notes);
    const [isDiagnosis, setIsDiagnosis] = useState(false)
      
    const {addProps} = useAddPrescription(actions, match);

    const {prescription, other, others} = addProps;

    useEffect(() => {
        if (!match){
            return
        }
        setIds({...match})
        setPatientNumber(match?.patient_number)
    }, [match]);


    useEffect(() => {
        if (!match.patient_admission_id){
            return
        }
        setAdmissionId(match.patient_admission_id)
    }, [!match.patient_admission_id]);

    useEffect(() => {
        if (!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setInsuranceDetails(dt)
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [visit_id]);

    useEffect(()=>{
        if(!visit_id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            const diagnosis = details?.diagnosis?.length <= 0
            setIsDiagnosis(diagnosis)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })

    },[visit_id])

    useEffect(()=>{
        if (!insuranceDetails){

        }
        setInsuranceScheme({value:insuranceDetails?.insurance_scheme_id, label:insuranceDetails?.insurance_scheme_name})
        const isInsurance = insuranceDetails?.is_on_insurance === 1
        setIsOnInsurance(isInsurance)
        setInsuranceStatus(insuranceDetails?.insurance_scheme_status)

    },[insuranceDetails])

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const provArr = arr.map(item=>({value:`${item.provider_id}-${item.provider_type}-${item.provider_name}`, label:item.provider_name}))
            setProviders(provArr);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cuser/view_company_info`)
            .then((response) => {
                const data= response.data? response.data:{}
                setCompanyName(data.company_name);
            }).catch(err=>{
                errorMessages(err, null, actions)
        });
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    const handleChangeInsuranceStatus = (event) =>{
        setInsuranceStatus(event.target.value)
    }

    const handleChangeInsuranceScheme = (value) =>{
        setInsuranceScheme(value)
    }


    const fetchInsuranceSchemes = (_id) =>{
        const insurance_id = _id.split('-')[0]
        const ins_id = Number(insurance_id)
        axios.get(`${config.smsUrl}/insurance-scheme/insurance-provider/${ins_id}`)
            .then(res => {
                const details = res.data;
                const dt = details ? details : []
                const arr = dt.map(item=>({
                    value:item.id,
                    label:item.name
                }))
                setInsuranceSchemes(arr)

            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
    }


    const fetchPatientDetails = (patientNumber) =>{
        const formData = new FormData()
        formData.append('patient_number', patientNumber);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData)
            .then(res => {
                const data = res.data;
                const details = !data ? {} : data;
                const {first_name, last_name} = details;
                const provider_id = details.provider_id ? `${details.provider_id}-${details.provider_type}-${details.provider_name}` : ''
                const provider_name = details.provider_id ? details.provider_name : ''

                setProviderId({value:provider_id, label:provider_name})
                setIsProvider(provider_id)

                if (provider_id){
                    fetchInsuranceSchemes(provider_id)
                }
                setProviderDetails({account_name: details.account_name ? details.account_name : '',
                    card_number: details.card_number ? details.card_number : '',provider_type: details.provider_type ? details.provider_type : ''})
                setPatientName(`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`)
            }).catch(error => {
            errorMessages(error,null, actions)
        })
    }

    useEffect(()=>{
        if (!patient_number){
            return;
        }

        fetchPatientDetails(patient_number)
        setShowNumber(false);

        if (ids.visit_id) {
            const formData2 = new FormData();
            formData2.append('visit_id', ids.visit_id);
            axios.post(`${config.smsUrl}/cdoctor/retrieve_exam_notes`, formData2).then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                const not = dt[dt.length-1] ? dt[dt.length-1] : {};
                const arr = not.notes ? not.notes : ''
                console.log(arr)
                if (arr){
                    setAdmissionNotes(arr);
                }
            }).catch(err => {
                errorMessages(err, null, actions)
            })
        }
    },[ patient_number]);

    const handleChangeReferral = (event) => {
        const {name, value} = event.target;
        setReferrals({...referrals, [name]: value})
    };


    const handleCloseReferral = () => {
        setOpenPrint(false)
    };

  
    const handleResetReferral = () => {
        setReferrals({
            referral_date: '', referral_number: "",
            visit_date: "", diagnosis: "", reason: "", notes: "",
            from_hospital: "", patient_history: "", treatment: ""
        });
        setSubmitted(false)
    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        const obj = {[visit_id]:{...note_item,[name]:value}}
        sessionStorage.setItem(`${user_roles_id}_${visit_id}`, JSON.stringify({...item, ...obj}))
    };

    const handleChangeRelationship = value =>{
        setRelationship(value)
        const obj = {[visit_id]:{...note_item,relationship:value}}
        sessionStorage.setItem(`${user_roles_id}_${visit_id}`, JSON.stringify({...item, ...obj}))
    }


    const handleChangeProvider = (value) =>{
        setProviderId(value)
        setNewProviderDetails({account_name: '', card_number: ''})
    }

    const handleChangeProviderDetails = (event) =>{
        const {name, value} = event.target
        setProviderDetails({...providerDetails, [name]:value})
    }

    const handleChangeNewProvider = (value) =>{
        setNewInsurance(value)
        setProviderDetails({account_name: '', card_number: ''})
    }

    const handleChangeNewProviderDetails = (event) =>{
        const {name, value} = event.target
        setNewProviderDetails({...newProviderDetails, [name]:value})
    }

    const handleChangeIsInsurance = (event) =>{
        setIsOnInsurance(event.target.checked)
    }

    const handleAddReferral = (event) =>{
        setAddReferral(event.target.checked)
    }

    const handleOpenNewInsurance = () =>{
        setOpenNewInsurance(true)
    }

    const handleCloseNewInsurance = () =>{
        setOpenNewInsurance(false)

    }

    const handleSubmitProvider = (event) =>{
        event.preventDefault()
        const prov = new_insurance ? new_insurance : {}
        const prov_value = prov.value ? prov.value : ''
        const [id, provider_type] = prov_value.split('-')
        const isInsurance = provider_type === 'Insurance Company'
        const {account_name, card_number} = newProviderDetails
        const formData = new FormData()
        formData.append('patient_number', p_number)
        formData.append('provider_id',  id)
        formData.append('account_name', account_name ? account_name:'')
        formData.append('card_number', card_number ? card_number: '')
        setSubmitProvider(true)
        const isProviderRequired = (isInsurance && account_name && card_number) || (!isInsurance && account_name)
        
        if(new_insurance && isProviderRequired){
            setIsSubmitted('pendingInsurance')
            axios.post(`${config.smsUrl}/cpatient/patient_insurance_detail/add`, formData).then((res) => {
                setIsSubmitted('resolvedInsurance')
                setOpenNewInsurance(false)
                fetchPatientDetails(p_number)
            }).catch((err) => {
                setIsSubmitted('rejectedInsurance');
                errorMessages(err, null, actions)
            });
        }
    }

    const handleChangePatientNumbers = (event) => {
        setPatientNumber(event.target.value);
        const formData = new FormData();
        if (event.target.value !== '') {
            const patient_no = search_parameter === 'patient_no' ? event.target.value.toUpperCase() : ''
            const patient_name = search_parameter === 'patient_name' ? titleCase(event.target.value) : ''
            const phone_no = search_parameter === 'phone_no' ? event.target.value : ''
            formData.append('patient_number', patient_no);
            formData.append('name', patient_name);
            //formData.append('last_name', '');
            formData.append('phone_no', phone_no);
            axios.post(`${config.smsUrl}/cadmission/autosearch_non_admission`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    if (data[0] === "No Patient Found") {
                        setPatientNumbers([{value: '', label: 'No patient found'}])
                    } else {
                        const arr = data.map(item => ({
                            value: item.patient_number,
                            label: `(${item.patient_number}) ${item.first_name} ${item.last_name} - ${item.phone_no}`
                        }))
                        setPatientNumbers(arr)
                    }
                }).catch(error => {
               errorMessages(error,null, actions)
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
            setProviderId('')
            setProviderDetails({account_name: '', card_number: ''})
            setPatientName('')
        }

    };

    const handleChangeSearchParam = (event) =>{
        setSearchParameter(event.target.value)
        setPatientNumber('')
        setPatientNumber('')
        setPatientName('')
        setShowNumber(false);
    }

    const handleClickPatientNumber = (event) => {
        setPatientNumber(event.value);
        fetchPatientDetails(event.value)
        setShowNumber(false);
    };

    const handleReset = () => {
        setState({
            admission_date: "",
            first_name: "",
            last_name: "",
            address: '',
            nin: ''
        });
        setContact('');
        // setPatientNumber('');
        setRelationship('');
        setState({admission_date: dateConvert(),
            first_name: "", last_name: "",  address: '', nin: ''
        });
    };

    const handleClosePrintDialog = () => {
        setOpenPrintDialog(false)
    };

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleOpenAssignBed = () =>{

        const {admission_date} = state;
        const {account_name, card_number} = providerDetails
        const [_, provider_type] = provider_id?.value?.split('-')
        const {
            diagnosis, from_hospital
        } = referrals;

        const referral_data = (addReferral && (from_hospital && diagnosis)) || !addReferral

        const isInsurance = provider_type === 'Insurance Company'


        const schemeIds = [insurance_scheme].map(item=>({
            scheme_id:item.value
        }))

        const required_data = (isProvider && ((isInsurance && account_name && card_number && insurance_status && schemeIds.length > 0) || (!isInsurance && account_name))) || !isProvider
        const required_insurance = (isOnInsurance && required_data) || !isOnInsurance

        setSubmitted(true)
        if (p_number && admission_date &&  referral_data && required_insurance && findings && !isDiagnosis) {
            setOpenAssignBed(true)
        }
    }

    const assignBed = () =>{
        setOpenDialog(true)
        setOpenAssignBed(false)
    }

    const handleCloseAssignBed = () =>{
        setOpenAssignBed(false)
    }


    const handleSubmit = event => {
        event.preventDefault();
        const {admission_date, first_name,  address, nin} = state;
        const {account_name, card_number} = providerDetails
        const [id, provider_type] = provider_id?.value?.split('-')
        const {
           diagnosis,treatment, patient_history, from_hospital
        } = referrals;
         const{visit_id} = ids
         const record_incoming_referral = JSON.parse(sessionStorage.getItem(`record_incoming_referral_${p_number}`));
         const is_referred = record_incoming_referral !== null;
         const referral_data = (addReferral && (from_hospital && diagnosis)) || !addReferral

        const isInsurance = provider_type === 'Insurance Company'


        const schemeIds = [insurance_scheme].map(item=>({
            scheme_id:item.value
        }))

        const data ={
            patient_number: p_number,
            visit_id: visit_id,
            patient_type: 2,
            department_id: department_id,
            first_name,
            relationship: relationship ? relationship.value : '',
            phone_no: contact ?  convertPhone(contact):contact,
            address, nin,
            user_roles_id: user_roles_id,
            provider_id: +id,
            is_on_insurance: isOnInsurance ? 1 : 0,
            account_name: account_name,
            card_number: card_number,
            scheme_id:schemeIds.length > 0 ? schemeIds : '',
            insurance_scheme_status:insurance_status ? insurance_status : '',
            findings: findings,
            bill_type:3,
            sundries:[],
            products: prescription.filter(item => Boolean(item)).filter(drug => drug.product_id).map(item=>({...item, quantity_prescribed: +item.quantity})),
            other_products:others ? others.filter(item=>Boolean(item)).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                drug.frequency || drug.number_of_days) : [],
            is_referred: addReferral, 
            referral_data: addReferral === false ? '' : {
                referred_from : from_hospital,
                referred_to: company_name,
                referral_type: "incoming",
                visit_date: '',
                diagnosis: diagnosis,
                reason: patient_history,
                treatment_given: treatment,
                user_role_id: ''
            }
        }
        setSubmitted(true);

        const required_data = (isProvider && ((isInsurance && account_name && card_number && insurance_status && schemeIds.length > 0) || (!isInsurance && account_name))) || !isProvider
        const required_insurance = (isOnInsurance && required_data) || !isOnInsurance

        const isNurse = (nurseAdmission && isDiagnosis) || !(nurseAdmission && diagnosis)


        if (referral_data && required_insurance && findings!=='' && isNurse) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cadmission/add_admission`, data).then(res => {
                setIsSubmitted('resolved');
                const data = res.data;
                const dt = !data ? {} : data;
                if(dt.message === "Already Exists"){
                    actions.snackbarActions.snackError("Patient already admitted");
                }else{
                    const admission_id = !dt.patient_admission_id ? "" : dt.patient_admission_id;
                    const visit_id = !dt.visit_id ? "" : dt.visit_id;
                    setIds({visit_id, patient_name})
                    actions.snackbarActions.snackSuccess("Patient admission registered successfully");
                    if (admission_id){
                        setAdmissionId(admission_id)
                        setOpenDialog(true)
                        setOpenAssignBed(false)
                    }
                }
                sessionStorage.removeItem(`${user_roles_id}_${visit_id}`)
                handleReset();
                setSubmitted(false);
            }).catch(err => {
                setSubmitted(false);
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleReferralDialog = (e) => {
        e.preventDefault();
        setReferralDialog(!referralDialog)
    }

    const handleClick = () => {
        const {visit_id} = ids
        history.push(`/admissiontemplate/${patient_admission_id}/${visit_id}`)
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    // hooks for the admission notes
    const handleChangeNotes = (event) => {
        const _note = event.target.value;
        setAdmissionNotes(_note);
        const obj = {[visit_id]:{...note_item,notes:_note}}
        sessionStorage.setItem(`${user_roles_id}_${visit_id}`, JSON.stringify({...item, ...obj}))

    }

    const handleCloseAlert = () =>{
        setIsDiagnosis(false)
    }

    const isPending = isSubmitted === 'pending';

    const isPendingProvider = isSubmitted === 'pendingInsurance'

    const providerProps = {submitted,provider_id,handleChangeProvider,providers,handleChangeProviderDetails,
        providerDetails}

    const newProviderProps = {submitted:submitProvider,provider_id:new_insurance,handleChangeProvider:handleChangeNewProvider,
        providers,handleChangeProviderDetails:handleChangeNewProviderDetails,
        providerDetails:newProviderDetails}

    const referralProps = {submitted,isSubmitted,company_name, handleChangeReferral,
        handleCloseSnackbar,handleResetReferral,openPrint,referrals,handleCloseReferral}

    const admissionProps ={state, p_number, showNumber, patientNumbers, submitted, addProps, search_parameter, handleChangeSearchParam,
        patient_name, handleChange, handleChangePatientNumbers, handleClickPatientNumber, handleReset,patient_number,
         handleSubmit, contact,setContact, isPending, relationship, handleChangeRelationship,
        findings, handleChangeNotes,isProvider, provider_id, providers, providerDetails, handleChangeProviderDetails,
        handleChangeProvider, isOnInsurance, addReferral, handleAddReferral, handleChangeIsInsurance,providerProps, openNewInsurance, handleCloseNewInsurance,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance, newProviderProps, handleReferralDialog,referralDialog,
        insurance_status, handleChangeInsuranceStatus,isDiagnosis,handleCloseAlert,
        insuranceSchemes, insurance_scheme, handleChangeInsuranceScheme, patient_admission_id}

    return {admissionProps, printDialog,handleClosePrintDialog, handleSubmit, handleClick, closeSnackbar,setPatientNumber,userObj,referralProps,
        token, _token,department_id,user_roles_id,ids, setState, assignProps, handleCloseDialog, openDialog, openAssignBed,
    handleOpenAssignBed, assignBed, handleCloseAssignBed, patient_admission_id}

}