import {ResultsReportComponent} from "./ResultsReportComponent";
import React, {useState} from "react";
import {RightAlignedContainer} from "../Utils/styledComponents";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";

function ApproveLabResults({actions, ids, setOpenDialog, openApproval,setOpenApproval, setIsApproved}) {

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const [approve, setApprove] = useState(false)


    const handleApproval = (status) =>{
        const formData = new FormData();
        formData.append('labrequests_detail_id', ids?.labrequests_detail_id);
        formData.append('status', status);
        formData.append('user_roles_id', user_roles_id);
        setIsApproved('pending')
        axios.post(`${config.smsUrl}/claboratory/update_lab_result_status`, formData).then(() => {
            const message = status === 1 ? 'approved' : 'canceled'
            actions.snackbarActions.snackSuccess(`Lab results ${message} successfully`);
            setOpenApproval(false)
            setApprove(false)
            setIsApproved('resolved');
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsApproved('rejected');
        })
    }
    return(
        <CustomDialog title='Approve Results' open={openApproval} handleClose={()=>setOpenApproval(false)} maxWidth="md"  isContentOverflow={false}>
            <PrintDialog handleCancel={()=>setApprove(false)} openDialog={approve} handleClose={()=>setApprove(false)}
                         handleClick={()=>handleApproval(1)} message={"Approve Results"}
                         text='Are you sure you want to approve these results?'/>
        <div style={{padding:'8px 20px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
            <RightAlignedContainer>
                <button onClick={()=>setApprove(true)} className="btn sms-info-btn mr-3 btn-sm">Approve</button>
                <button onClick={()=>{
                    setOpenDialog(true)
                    setOpenApproval(false)
                }} className="btn sms-gray-btn btn-sm">Edit</button>
            </RightAlignedContainer>
        </div>
        <div style={{padding:"20px"}}>
            <ResultsReportComponent {...{test_results_id:ids.test_results_id, actions}} />
        </div>
    </CustomDialog>
    )
}
export {ApproveLabResults}