import React, {useCallback, useLayoutEffect, useState} from "react";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";
import {dateConvert, formatDateTime} from "../Utils/ConvertDate";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {history} from "../../Helpers/history";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor', hidden:false},
    {id: 'department_name', numeric: false, disablePadding: false, label: 'Clinic', hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden:false},
];

export const LabReports = ({actions, snackbars}) => {
    const [searchValue, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState({requests: [], total_count: 0, loading: 'idle'})
    const [state, setState] = useState({start_date: dateConvert(), end_date: dateConvert()})

    const {start_date, end_date} = state


    const getAllResults = useCallback(() => {
        (async () => {
            setData({...data, loading: 'pending'})
            try {
                const formData = new FormData();
                formData.append('search', searchValue);
                formData.append('start_date', state.start_date);
                formData.append('end_date', state.end_date);
                formData.append('start', activePage);
                const res = await axios.post(`${config.smsUrl}/claboratory/get_all_finished_lab_requests`, formData);

                const response = res.data ?? {}
                const arr = response?.data ?? [];
                const count = response?.iTotalRecords ?? 0;
                const list = arr.map((item, index)=>{
                    const count = ((activePage - 1) * 10) + index + 1;
                    return {
                        ...item,
                        count,
                        date:item.time_requested ? formatDateTime(item.time_requested) : "",
                        patient_name:`${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
                        doctor_name:`${item.doctor_firstname} ${item.doctor_lastname}`,
                        phone_no:item.patient_Phone_number,
                        kin_phone_no:item.patient_Kin_Phone_number,
                        action: (
                            <Link to={{pathname: `/generallabreport/${item.patient_number}/${item.labrequest_id}`}}><span
                                className="mr-1 mb-1 icon-button">
                    <FontAwesomeIcon icon={faEye}/></span></Link>
                        )
                    }
                })
                setData({...data, requests: list, loading: 'success', total_count: count})
            } catch (error) {
                setData({...data, loading: 'error'})
                logoutErrorMessage(error, null, actions);
            }
        })()
    }, [activePage, state.start_date, state.end_date, searchValue])

    useLayoutEffect(() => {
        getAllResults();
        const interval = setInterval(() => {
            getAllResults();
        }, 10000);

        return () => clearInterval(interval);
    }, [getAllResults]);


    const handleChange = (event) => {
        const {value, name} = event.target
        setState({...state, [name]: value})
    }
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {openBar, type, message} = snackbars;

    const components = [{label: 'Pending Lab Requests', path: "/labrequests"},
        {label: 'Pending Lab Results', path:  "/pendingsamples"}, {label: 'Lab Results', path: "/labresultlist"},
        {label: 'Lab Reports', path: "/labreports"}]


    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const isLoading = data.loading === 'pending';
    const isSuccess = data.loading === 'success';
    const isError = data.loading === 'error';

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={data.total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );


    return (
        <div className='journals'>
            <PageTitle title='Lab Reports'/>
            <SubHeader title="Laboratory" subTitle='Lab Reports'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables'>
                <DateTimeFilter {...{start_date,end_date, handleChange, isFilter:false}}/>
                <CustomTable title="Lab Reports" headData={headCells} handler={handleSearch}
                             activePage={activePage} {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             records={10} total_count={data.total_count} pagination={pagination}
                              data={data.requests} colSpan={headCells.length} term={searchValue}>
                        <TableBody>
                            {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow>: null}
                        {isSuccess ? data.requests.length > 0 ?
                                data.requests.slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabReports);
