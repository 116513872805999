import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import TextField from "../Utils/FormInputs/TextField";
import {ErrorMessage, RightAlignedContainer} from "../Utils/styledComponents";
import ReusablePrescriptionDetails from "./ReusablePrescription";
import {
    ExpiredStatus,
    FinishedStatus,
    PendingStatus,
    Processing,
    StyledCell,
    StyledIcon,
    StyledRemoveIcon
} from "../DoctorsModule/doctorStyles";
import {AutoCompleteProducts, AutoCompleteUnControlled} from "../Utils/FormInputs/AutoCompleteInput";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import styled from "@emotion/styled/macro";
import CloseIcon from "@material-ui/icons/Close";
import {DataCell} from "../Utils/Lists/CollapsibleList";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {titleCase} from "../Users/addUser";
import {convDate, dateConvert, formatDateTime} from "../Utils/ConvertDate";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../Helpers/history";
import Alert from "@material-ui/lab/Alert";

const StyledEditIcon = styled(StyledIcon)({
    fill: 'white',
    width: '0.6em',
    height: '0.6em'
})

const StyledCloseIcon = styled(CloseIcon)({
    fill: '#c4495c',
    width: '0.6em',
    height: '0.6em'
})

const StyledIconButton = styled(IconButton)({
    background: '#1361c1',
    padding: '3px',
    '&:hover': {
        background: '#1361c1',
    }
})

const Expired = styled(ExpiredStatus)({
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '10px'
})

const FillPrescription = ({
                              component, actions, openDialog, setOpenDialog, prescriptionIds, setPrescriptionIds,
                              isSubmitted, setIsSubmitted
                          }) => {
    const [rows, setRows] = useState([]);
    const [state, setState] = useState({
        patient_id: '', patient_name: '', gender: '', age: '', weight: '', blood_pressure: '',
        reference: '', visit_id: '', date: '', policy_number: '', address: '', phone: '',
        dispensed_by: '', dispensed_at: ''
    });
    const [drugList, setDrugList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [product_id, setProductId] = useState('')
    const [isReset, setIsReset] = useState('idle')
    const {prescription_id, patient_type, visit_id, item_status} = prescriptionIds;

    const [current_batches, setCurrentBatches] = useState([]);
    const [current_idx, setCurrentIdx] = useState();

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;

    const route = {
        'out-patient': 'get_patient_presc',
        'in-patient': 'get_patient_presc',
        'previous': 'get_previous_patient_presc'
    }

    /**
     * Fetch the details of a prescription
     */
    useEffect(() => {
        if (!(prescription_id && visit_id && component)) {
            return
        }
        const formData = new FormData();
        formData.append('prescription_id', prescription_id);
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cpharmacy/${route[component]}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const prescription = !dt.patient_info ? {} : dt.patient_info;
            const out_patient = dt.out_presc ?? [];
            const in_patient = dt.in_presc ?? [];
            const prescrip = [...out_patient, ...in_patient]
            setState({
                patient_id: prescription.patient_number,
                patient_name: `${!prescription.patient_firstname ? "" :
                    prescription.patient_firstname} ${!prescription.patient_lastname ? "" :
                    prescription.patient_lastname}`, gender: prescription.gender,
                age: prescription.age, weight: prescription.weight, blood_pressure: prescription.blood_pressure,
                reference: prescription.reference, visit_id: prescription.visit_id, date: prescription.begin_datetime,
                policy_number: prescription.policy_number, address: prescription.address, phone: prescription.phone_no,
            })

            const prdts = prescrip.map(product => ({
                ...product,
                medicine_name: product.product_name,
                alternative_product_id: '',
                alternative_product_name: '',
                dosage: product.dosage,
                quantity: product.quantity ? product.quantity : 0,
                number_of_days: product.number_of_days,
                cost: product.amount,
                instructions: product.key_information,
                prescription_detial_id: product.prescription_detial_id ?? '',
                frequency: product.frequency,
                bill_id: product.bill_id ? product.bill_id : '',
                isError: false,
                total_chosen_quantity: product.quantity_given ? product.quantity_given : 0,
                isChange: false,
                isCorrectQuantity:true,
                alertMessage:null,
                openAlert:false,
                quantity_billed: product.quantity_prescribed ? product.quantity_prescribed : product.quantity_billed,
                quantity_given: product.quantity_given ? product.quantity_given : 0,
                dispensed_by: `${!product.dispensed_by_frist_name ? "" :
                    product.dispensed_by_frist_name} ${!product.dispensed_by_last_name ? "" :
                    product.dispensed_by_last_name}`,
                dispensed_at: product.dispensed_at ? formatDateTime(product.dispensed_at) : '',
                selected_batch_ids: [{
                    batch_id: '', available_quantity: 0, chosen_quantity: 0, isError: false, isDuplicate: false
                }],
            }));
            setRows(prdts);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [prescription_id, visit_id, component]);


    /**
     * Changes to alternative medicine for an insurance patient
     * @param index - The index of the current medicine to be changed
     */
    const handleChangeMedicine = (index) => {
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {...item, isChange: true}
            }
            return item
        })
        setRows(arr)
        setIsBlocking(true)
    }


    /**
     * Searches for an alternative medicine using the product name
     * @param eventTxt - The product name passed by the MUI Autocomplete
     * @param idx - The index of the alternative medicine searched
     */
    const retrieveDrugs = (eventTxt, idx) => {
        const arr = rows.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('product_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data[0] === "No Product Found") {
                            setDrugList([{value: '', label: 'No medicine found'}])
                        } else {
                            const arr = data.map(item => ({
                                value: item.value,
                                label: `${item.label}-${item.generic_name}-${item.country_of_origin}`
                            }))
                            setDrugList(arr);
                        }
                    }).catch(error => {
                        // logoutErrorMessage(error, null, actions)
                    });
                    return {...item, alternative_product_name: eventTxt, showDrug: true, isError: false,}
                }
                return {...item, alternative_product_name: eventTxt, showDrug: false, isError: false}
            }
            return item
        })
        setRows(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    /**
     * Retrieves the details of the product searched
     * @param event - Object passed from the MUI Autocomplete
     * @param idx - Index of the current row in the table
     */

    const clickDrug = (event, idx) => {
        const {visit_id: v_id} = prescriptionIds
        let arr = rows.map((item, index) => {
            if (index === idx) {
                return {
                    ...item,
                    alternative_product_name: event.innerText,
                    alternative_product_id: event.value,
                    generic_name: event.generic_name,
                    isError: false,
                    showDrug: false
                }
            }
            return item
        })
        axios.post(`${config.smsUrl}/cbilling/item_cost`, {
            visit_id: +v_id,
            item_id: +event.value,
            item_type: 'product'
        }).then(res => {
            const resObj = res.data;
            const dataObj = !resObj.data ? {} : resObj.data;
            arr = arr.map((item, index) => {
                if (index === idx) {
                    return {
                        ...item, rate: dataObj.cost ?? 0, ...dataObj,
                        currency: dataObj.currency_id, currency_symbol: dataObj.currency
                    }
                }
                return item
            })
            setRows(arr)
            if (dataObj.is_provider === 1 && dataObj.is_provided === 0) {
                setProductId(event.value)
                setOpenConfirmation(true)
            }
            setIsSubmitted('clickedDrug');
        }).catch(error => {
            // errorMessages(error, null, actions)
        });


        setIsBlocking(true)
    }

    /**
     * Close dialog to check if the product changed to is covered by the insurance provider
     */
    const handleCloseConfirmation = () => {
        let arr = rows.map((item, index) => {
            if (item.product_id === product_id) {
                return {
                    ...item, alternative_product_name: '', alternative_product_id: '', generic_name: '',
                    isError: false, showDrug: false
                }
            }
            return item
        })
        setIsReset('reset')
        setRows(arr)
        setOpenConfirmation(false)
    }

    /**
     * Close confirmation dialog if the user clicks NO
     */
    const handleCancelConfirmation = () => {
        setOpenConfirmation(false)
    }


    /**
     * add a new row to the batch_ids list for a prescription
     * @param index
     * @param item
     */
    const handleAddBatchRow = (index, item) => {
        let allRows = [...rows];
        let new_selected_batches = item['selected_batch_ids'];
        new_selected_batches.push({
            batch_id: '',
            available_quantity: 0,
            chosen_quantity: 0,
            isError: false,
            isDuplicate: false,
            isExpired: false
        });
        allRows[index]['selected_batch_ids'] = new_selected_batches;
        setRows(allRows);
        setIsBlocking(true)
    };

    // remove row for the batch ids
    const removeBatchRow = (index, btch_idx, item) => {
        let allRows = [...rows];
        let new_selected_batches = item['selected_batch_ids'];
        new_selected_batches.splice(btch_idx, 1);
        allRows[index]['selected_batch_ids'] = new_selected_batches;
        setRows(allRows);
        setIsBlocking(true)
    };


    function addExpirationStatus(array) {
        return array.map(item => {
            const isExpired = convDate(item.expiry_date) <= dateConvert();
            return {...item, isExpired};
        }).filter(item => !item.isExpired);
    }

    const handleOnClick = (idx) => {
        if (idx !== current_idx) {
            setCurrentIdx(idx);
        }

        const product_id = rows[idx]['alternative_product_id'] ? rows[idx]['alternative_product_id'] : rows[idx]['product_id']
        const formData = new FormData();
        formData.append('product_id', product_id);
        axios.post(`${config.smsUrl}/cinventory/get_pharmacy_batches`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const batches = data.product ? data.product : [];
            const batchesArr = batches.map(item => ({
                ...item,
                quantity: item.quantity ? item.quantity : 0
            }))

            const btches = batchesArr.filter(item => item.quantity > 0).map(item => ({
                ...item,
                value: item.batch_id,
                label: item.batch_id,
                product_id
            }))
            const batch_arr = addExpirationStatus(btches)

            setCurrentBatches(batch_arr);
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
        setIsBlocking(true)
    }

    const retrieveBatches = (eventTxt, idx) => {
        if (idx !== current_idx) {
            setCurrentIdx(idx);
        }
        const arr = rows.map((item, index) => {
            if (index === idx) {
                const product_id = item['alternative_product_id'] ? item['alternative_product_id'] : item['product_id']
                const formData = new FormData();
                formData.append('product_id', product_id);
                let btches = [];
                axios.post(`${config.smsUrl}/cinventory/get_pharmacy_batches`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? {} : resp;
                    const batches = data.product ? data.product : [];
                    btches = batches.map(batch => ({
                        ...batch,
                        value: batch.batch_id,
                        label: batch.batch_id,
                        product_id: item.product_id
                    }))
                    const batch_arr = addExpirationStatus(btches)
                    setCurrentBatches(batch_arr);
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
                return {...item, test_name: eventTxt, batches: btches, isError: false};
            }
            return item
        })
        setRows(arr);
        setIsBlocking(true)
    }

    const getBatchDetails = (event, idx, btch_idx) => {
        // get the drugs
        let allRows = [...rows];

        if (!event.innerText) {
            allRows[idx]['selected_batch_ids'][btch_idx]['batch_id'] = '';
            allRows[idx]['selected_batch_ids'][btch_idx]['available_quantity'] = '';
            allRows[idx]['selected_batch_ids'][btch_idx]['chosen_quantity'] = '';
            return;
        }

        allRows[idx]['selected_batch_ids'][btch_idx]['chosen_quantity'] = ''

        // get the selected batch_ids
        let new_selected_batches = allRows[idx]['selected_batch_ids'];

        const check_dups = new_selected_batches.some((item) => (item['batch_id'] === event.innerText));

        if (check_dups) {
            new_selected_batches[btch_idx]['isDuplicate'] = true;
        } else {
            new_selected_batches[btch_idx]['isDuplicate'] = false;
        }

        new_selected_batches[btch_idx]['isExpired'] = event.isExpired;

        // set the batch_id
        new_selected_batches[btch_idx]['batch_id'] = event.innerText;

        // const product_id = allRows[idx]['alternative_product_id'] ? allRows[idx]['alternative_product_id'] : allRows[idx]['product_id']
        new_selected_batches[btch_idx]['available_quantity'] = event.quantity < 0 ? 0 : event.quantity;
        new_selected_batches[btch_idx]['isBatch'] = false;

        allRows[idx]['selected_batch_ids'] = new_selected_batches;
        setRows(allRows);
        // const formData = new FormData();
        // formData.append('batch_id',  event.innerText);
        // formData.append('product_id', product_id)
        // axios.post(`${config.smsUrl}/cinventory/get_pharmacy_stock`, formData).then(response => {
        //
        //     const res = response.data;
        //     const data = res ? res : {};
        //     const t_product = data.total_product ? data.total_product : 0;
        //
        //     new_selected_batches[btch_idx]['available_quantity'] = event.quantity < 0 ? 0 : event.quantity;
        //     new_selected_batches[btch_idx]['isBatch'] = false;
        //
        //     allRows[idx]['selected_batch_ids'] = new_selected_batches;
        //     setRows(allRows);
        //
        // }).catch(error => {
        //     errorMessages(error, null, actions)
        // });
        setIsBlocking(true)
    }

    const handleChosenBatchQuantity = (event, idx, btch_idx) => {
        const {value} = event.target;
        let allRows = [...rows];
        allRows[idx]['selected_batch_ids'][btch_idx]['chosen_quantity'] = value;
        let new_btches = allRows[idx]['selected_batch_ids'];

        const selected_product = allRows[idx]['product_id']
        const selected_batch = allRows[idx]['selected_batch_ids'][btch_idx]['batch_id']
        const available_quantity = allRows[idx]['selected_batch_ids'][btch_idx]['available_quantity']
        const product_name = allRows[idx]['medicine_name']



        // calc the total_chosen_quantity
        let new_total_chosen_quantity = 0;
        for (var x = 0; x < new_btches.length; x++) {
            new_total_chosen_quantity += parseInt(new_btches[x].chosen_quantity ? new_btches[x].chosen_quantity : 0);
        }

        allRows[idx]['total_chosen_quantity'] = parseInt(new_total_chosen_quantity);
        if (patient_type === 2) {
            allRows[idx]['quantity_given'] = new_total_chosen_quantity;
        }

        let total_quantity_to_dispense = allRows.filter(item => item.product_id === selected_product &&
            item.selected_batch_ids.every(bat => bat.batch_id !== '' && bat.batch_id === selected_batch)).reduce((prev, curr) => +prev + +curr.total_chosen_quantity, 0)

        allRows[idx]['isCorrectQuantity'] = total_quantity_to_dispense <= available_quantity
        allRows[idx]['alertMessage'] = (
            <span>The quantity to dispense for batch <strong>{selected_batch}</strong> of <strong>{product_name}</strong> exceeds the available stock</span>
        )
        if (total_quantity_to_dispense > available_quantity){
            allRows[idx]['openAlert'] = true
        }else {
            allRows[idx]['openAlert'] = false
        }

        setRows(allRows);
        setIsBlocking(true)
    }

    const handleCloseAlert = (idx) =>{
        const arr = rows.map((item, index)=>{
            if (index === idx){
                return {...item, openAlert:false}
            }
            return item
        })
        setRows(arr)
    }


    const handleCloseDialog = () => {
        // clear everything
        if (isBlocking) {
            setIsBlockDialog(true)
        } else {
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false);
            setPrescriptionIds({prescription_id: '', patient_type: '', visit_id: ''});
            setCurrentBatches([]);
            setCurrentIdx();
            setRows([]);
            // setDrugs([]);
            setSubmitted(false);
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let contains_duplicates = false;
        let unequal_chosen_quantities = false;

        const products_dispensed =
            rows.filter(item => ![4, 2].includes(parseInt(item.status)))
                .map((item) => {

                    const btches = item.selected_batch_ids.filter((it) => (it.batch_id)).map((ex) => {

                        // check if the user chose batch_ids that are the same
                        if (ex.isDuplicate && ex.isExpired && !contains_duplicates) {
                            contains_duplicates = true;
                        }

                        return {
                            'batch_id': ex.batch_id,
                            'quantity': parseInt(ex.chosen_quantity),
                            'quantity_billable': Number(item.quantity)
                        }
                    })

                    // check if the chosen quanitities is equal to the required quantity
                    // if (parseInt(item.total_chosen_quantity) !== parseInt(item.quantity_billed) && !unequal_chosen_quantities) { unequal_chosen_quantities = true;}

                    return {
                        'quantity': parseInt(item.quantity_billed),
                        'prescription_detial_id': item.prescription_detial_id,
                        'bill_id': item.bill_id,
                        'product_id': item.product_id,
                        'drug_change': item.isChange,
                        'alternative_product_id': item.alternative_product_id,
                        'batches': btches,
                        prescription_id: item.prescription_id,
                        total_chosen_quantity: parseInt(item.total_chosen_quantity),

                    }
                })


        let params = {
            // prescription_id,
            visit_id,
            products_dispensed: products_dispensed.map(({total_chosen_quantity, ...rest}) => ({...rest})).filter(item => item.batches.length > 0),
            user_roles_id
        }


        setSubmitted(true);

        const arr = products_dispensed.some(item => item.total_chosen_quantity === item.quantity)
            && products_dispensed.some(item => item.batches.length > 0) && rows.every(item=>item.isCorrectQuantity)


        let url = ''
        if (patient_type === 1) {
            url = 'fill_prescription';
            params['patient_type'] = 1;

        } else {
            rows.forEach(item => {
                if (item.discharge_status == null || item.discharge_status === true) {
                    url = 'fill_prescription';
                    params['patient_type'] = 1;
                } else {
                    url = 'fill_prescription';
                    params['patient_type'] = 2;
                }
            })
        }

        setIsBlocking(false)
        if (arr) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cpharmacy/${url}`, params).then(() => {

                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Prescription filled successfully');
                setRows([]);
                setOpenDialog(false);
                setSubmitted(false);
                setIsSubmitted('resolved');

            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected');
            })
        }
    };

    const handleResetForm = () => {
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false);
        setPrescriptionIds({prescription_id: '', patient_type: '', visit_id: ''});
        setCurrentBatches([]);
        setCurrentIdx();
        setRows([]);
        // setDrugs([]);
        setSubmitted(false);
    }

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';
    const isItemReset = isReset === 'reset'

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const isOutPatient = patient_type === 1
    const isInPatient = patient_type === 2
    const isPrevious = component === 'previous';
    const text = {
        'out-patient': 'Out-Patient',
        'in-patient': 'In-Patient',
        'previous': isOutPatient ? 'Out-Patient' : 'In-Patient'
    }

    const headData = [
        {name: 'Drug Name', width: 300},
        {name: 'Quantity Prescribed', width: 100},
        {
            name: 'Chosen Batch ID(s)',
            width: 300,
            sub_headings: isPrevious || item_status === 4 ? ["Batch ID", "Chosen Quantity"] :
                ["Batch ID", "Available Quantity", "Chosen Quantity", "Action"]
        },
        isInPatient ? {name: 'Quantity Given'} : null,
        isInPatient && isPrevious ? {name: 'Quantity Returned'} : null,
        {name: 'Dosage', width: 100},
        {name: 'Frequency'},
        {name: 'Number of Days', width: 100},
        {name: 'Instructions'},
        isPrevious ? {name: 'Dispensed By'} : null,
        isPrevious ? {name: 'Dispensed At'} : null,
        !isPrevious ? {name: 'Status'} : null];

    const dialogText = {
        'out-patient': 'Dispense Drug(s)',
        'in-patient': 'Dispense Drug(s)',
        'previous': 'Dispensed Drug(s)'
    }

    const alertRows = rows.filter(item=>!item.isCorrectQuantity)
    const showAlert = rows.some(item=>!item.isCorrectQuantity)

    const alertComponent = (
        showAlert ? alertRows.map((item, idx)=>{
            if (item.isCorrectQuantity === false){
                return(
                    <Alert key={idx} severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}} onClose={()=>handleCloseAlert(idx)}>
                        {item.alertMessage}
                    </Alert>
                )
            }

            }) : null
    )

    return (
        <>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={() => {
                    return isBlocking;
                }}
                navigate={path => history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={dialogText[component]} maxWidth="xl"
                          isContentOverflow={false}>
                <form onSubmit={handleSubmit} id="submit-prescription" autoComplete="off">
                    <CustomDialog open={openConfirmation} handleClose={handleCancelConfirmation} title='Change Drug'>
                        <p style={{margin: '10px 0', textAlign: 'center'}}>This drug is not on your insurance
                            cover,please choose another</p>
                        <RightAlignedContainer>
                            <button type='button' onClick={handleCloseConfirmation}
                                    className='btn btn-sm sms-info-btn small-btn'>Choose another drug
                            </button>
                        </RightAlignedContainer>
                    </CustomDialog>

                    {/*<ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation}*/}
                    {/*                    title='Make cash payment'*/}
                    {/*                    confirmItems={addItemsToReceipt}*/}
                    {/*                    removeItems={handleCloseConfirmation} service='product'/>*/}
                    <ReusablePrescriptionDetails {...{
                        headData: headData.filter(item => Boolean(item)),
                        text: text[component],
                        state,
                        visit_id,
                        alert:alertComponent
                    }}>
                        <tbody>
                        {rows.length > 0 ? rows.map((row, index) => {
                                //const isStopped = row.status === 7;

                                const outPatientStatus = {
                                    0: <PendingStatus>Pending</PendingStatus>,
                                    1: <FinishedStatus>Paid</FinishedStatus>,
                                    2: <FinishedStatus>Dispensed</FinishedStatus>
                                }

                                const inPatientStatus = {
                                    0: <PendingStatus>Pending</PendingStatus>,
                                    1: <PendingStatus>Pending</PendingStatus>,
                                    3: <PendingStatus>Pending</PendingStatus>,
                                    2: <FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
                                    4: <FinishedStatus>Dispensed</FinishedStatus>,
                                    6: <Processing>Being Discharged</Processing>,
                                    7: <ExpiredStatus>Stopped</ExpiredStatus>
                                }

                                const statusItem = isOutPatient ? outPatientStatus[row.status] : inPatientStatus[row.status]
                                const isOutpatientPending = row.status === 1
                                const isInpatientPending = row.status === 3 || row.status === 6

                                const medicine = (
                                    <>
                                        {row.medicine_name}
                                        {isOutPatient &&

                                            <span> <Tooltip title='Change drug'>
                                <StyledIconButton type='button' onClick={() => handleChangeMedicine(index)}>
                                    <StyledEditIcon/>
                                </StyledIconButton>
                            </Tooltip></span>}
                                    </>
                                );

                                const isMedicineChange = (
                                    <>

                                        <AutoCompleteProducts options={drugList} submitted={submitted} idx={index}
                                                              handleChange={retrieveDrugs} label_text="label"
                                                              medicine_value={{
                                                                  label: rows[index].alternative_product_name,
                                                                  value: rows[index].alternative_product_id,
                                                                  generic_name: rows[index].generic_name
                                                              }} id="drug_name" value_text="value"
                                                              errorMsg_Valid={"Enter valid drug name"}
                                                              valid_error_check={row.isError && submitted}
                                                              handleProductData={clickDrug} placeholder="Type drug name"
                                                              errorMsg="Enter drug name" reset={isItemReset}/>
                                        {/*<span> */}
                                        {/*    <Tooltip title='Cancel'>*/}
                                        {/*        <IconButton type='button' onClick={()=>handleCancelChangeMedicine(index)}>*/}
                                        {/*            <StyledCloseIcon/>*/}
                                        {/*        </IconButton>*/}
                                        {/*    </Tooltip>*/}
                                        {/*</span>*/}
                                    </>
                                )
                                return (
                                    <tr key={index}>
                                        <DataCell variant='200'>
                                            {isPrevious ? row.medicine_name : (row.isChange ? isMedicineChange : medicine)}
                                        </DataCell>
                                        <DataCell variant='100'>
                                            {/* {row.quantity_billed} */}
                                            {isInPatient ? rows[index]["quantity_prescribed"] : row.quantity_billed}
                                            {/*(isOutPatient || isPrevious || isStopped) ? row.quantity :
                                    <>
                                        <TextField disabled={row.discharge_status} submitted={submitted} type="number" value={row.quantity}
                                        min="0"
                                            onChange={(e) => handleChangeQuantity(e, index)}/>
                                        {submitted && !row.quantity ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                    </>
                                    */}
                                        </DataCell>

                                        <DataCell>
                                            {isPrevious || !(isOutpatientPending || isInpatientPending) ?
                                                <table>
                                                    <tbody>
                                                    {row.batches?.map((btch, btch_idx) => (
                                                        <tr key={btch_idx}>
                                                            <DataCell variant='100'>
                                                                {btch.batch_id}
                                                            </DataCell>
                                                            <DataCell variant='100'>
                                                                {btch.quantity}
                                                            </DataCell>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                                :
                                                <table>
                                                    <tbody>
                                                    {row.selected_batch_ids?.map((btch, btch_idx) => {

                                                            const renderOption = option => (
                                                                <>
                                                            <span style={{
                                                                display: "inline-block",
                                                                marginRight: '6px'
                                                            }}>{option.label}</span>{option.isExpired ?
                                                                    <Expired>Expired</Expired> : null}
                                                                </>
                                                            )
                                                            return (
                                                                <tr key={btch_idx}>
                                                                    <DataCell variant='150'>
                                                                        <AutoCompleteUnControlled options={current_batches}
                                                                                                  submitted={submitted}
                                                                                                  idx={index}
                                                                                                  handleChange={retrieveBatches}
                                                                                                  label_text="label"
                                                                                                  sub_idx={btch_idx}
                                                                            // onBlur={handleOnBlur}
                                                                                                  onFocus={() => handleOnClick(index)}
                                                                                                  medicine_value={{
                                                                                                      label: btch.batch_id,
                                                                                                      value: btch.batch_id
                                                                                                  }} id={`batch_id-${index}`}
                                                                                                  value_text="value"
                                                                                                  errorMsg_Valid="Please enter a valid batch id"
                                                                                                  valid_error_check={btch.isError}
                                                                                                  handleProductData={getBatchDetails}
                                                                                                  placeholder="Type Batch ID"
                                                                                                  errorMsg="Please select a batch ID"
                                                                                                  renderOption={renderOption}
                                                                        />
                                                                        {btch.batch_id && btch.isDuplicate && !btch.isExpired &&
                                                                            <ErrorMessage>This Batch ID was already
                                                                                selected</ErrorMessage>}
                                                                        {btch.batch_id && btch.isExpired &&
                                                                            <ErrorMessage>This batch is expired</ErrorMessage>}
                                                                    </DataCell>
                                                                    <DataCell variant='100'>
                                                                        {btch.available_quantity}
                                                                    </DataCell>
                                                                    <DataCell variant='100'>
                                                                        <TextField submitted={submitted} type="number"
                                                                                   disabled={btch.isExpired}
                                                                                   value={btch.chosen_quantity} min="0"
                                                                                   id={`quantity-${index}`}
                                                                                   onChange={(e) => handleChosenBatchQuantity(e,index, btch_idx)}
                                                                                   style={{fontSize: '11px'}}
                                                                                   required/>
                                                                        {/*{btch.chosen_quantity > btch.available_quantity &&*/}
                                                                        {/*    <ErrorMessage>Chosen Quantity is greater than*/}
                                                                        {/*        available*/}
                                                                        {/*        quantity</ErrorMessage>*/}
                                                                        {/*}*/}
                                                                        {submitted && (!btch.chosen_quantity) &&
                                                                            <ErrorMessage>This is a required
                                                                                field</ErrorMessage>}
                                                                    </DataCell>

                                                                    <DataCell>
                                                                        <Tooltip title='Remove'>
                                                                            <IconButton type='button'
                                                                                        onClick={() => removeBatchRow(index, btch_idx, row)}>
                                                                                <StyledRemoveIcon/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </DataCell>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                    <tr>
                                                        <StyledCell colSpan="4">
                                                            <button type="button"
                                                                    onClick={() => handleAddBatchRow(index, row)}
                                                                    className="btn btn-sm sms-gray-btn"
                                                                    id="add-row">Add another batch
                                                            </button>
                                                            {isOutPatient && submitted && (parseInt(row.total_chosen_quantity) !== parseInt(row.quantity_billed)) &&
                                                                <ErrorMessage>Chosen Quantity is not equal to prescribed
                                                                    quantity</ErrorMessage>}
                                                            {/* {submitted && (parseInt(row.total_chosen_quantity) !== parseInt(drugs[btch_idx]["quantity_prescribed"])) &&
                                                    <ErrorMessage>Chosen Quantity is not equal to prescribed quantity</ErrorMessage>} */}

                                                        </StyledCell>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            }
                                        </DataCell>

                                        {isInPatient ? <td>
                                                {row.quantity_given}
                                                {
                                                    (submitted && parseInt(row.total_chosen_quantity) !== parseInt(rows[index]["quantity_prescribed"])) &&
                                                    <ErrorMessage>Chosen Quantity {(row.total_chosen_quantity)} is not equal to
                                                        prescribed quantity
                                                        {rows[index]["quantity_prescribed"]}
                                                    </ErrorMessage>}
                                            </td>
                                            : null}
                                        {isInPatient && isPrevious ? <td>{row.quantity_returned}</td> : null}
                                        <td>{row.dosage}</td>
                                        <td>{row.frequency}</td>
                                        <td>{row.number_of_days}</td>
                                        <td>{row.instructions}</td>
                                        {isPrevious ? <td>{row.dispensed_by}</td> : null}
                                        {isPrevious ? <td>{row.dispensed_at}</td> : null}
                                        {!isPrevious ?
                                            <td>{statusItem}</td>
                                            : null}
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan="9" align="center"
                                    className={palette.type === 'dark' && 'dark-theme-color'}>No drugs
                                    prescribed
                                </td>
                            </tr>}
                        </tbody>
                    </ReusablePrescriptionDetails>
                    {!isPrevious ?
                        <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn"
                                disabled={isPending}>{isPending ? 'Saving...' : 'Save'}</button> : null}
                </form>
            </CustomDialog>
        </>

    )
};

export default FillPrescription;
