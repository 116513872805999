import React, {useEffect, useState} from "react";
import {usePrint} from "../../../Utils/Templates/usePrint";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {formatDate} from "../../../Utils/ConvertDate";
import ReusableDoctorTabs, {doctorRoutes} from "../../../Utils/Menu/ReusableDoctorTabs";
import PageTitle from "../../../Utils/smsTitle";
import {SubHeader} from "../../../../Containers/SubHeader";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";
import {CenteredContainer, RightAlignedContainer} from "../../../Utils/styledComponents";
import PatientLabReport from "../../../Lab/PatientLabReport";
import {LabResultsReport} from "../../../Lab/LabResultsReport";
import MainSnackbar from "../../../Utils/Snackbar/SmsSnackbar";
import {PatientInformation} from "../../PatientInfo/PatientInformation";
import {
    ActiveStatus,
    Container, ExpiredStatus,
    FinishedStatus, P,
    PendingStatus,
    Processing,
    StyledLibraryIcon, StyledRemoveIcon,
    ViewButton
} from "../../doctorStyles";
import {LoadingGif} from "../../../Utils/Loader";
import {CollapsibleList, DataCell, ModuleTable} from "../../../Utils/Lists/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../../actions/snackbarActions";
import {connect} from "react-redux";
import {AddLabRequest} from "./AddLabRequest";
import DeleteDialog from "../../../Utils/Dialogs/DeleteDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";

const headData = [{item:'Lab test', toolTip:'Make Lab test request here', isRequired:true},{item:'Lab', isRequired:true},
    {item:'Status', isRequired:false},{item:'Action', isRequired:false}]
const GeneralLabRequests = ({actions, snackbars, match:{params}}) => {
    const {patient_number, visit_id, patient_type,patient_admission_id} = params;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    const [labRequests, setLabRequests] = useState([])
    const [status, setStatus] = useState('idle')
    const [openReport, setOpenReport] = useState(false)
    const [id, setId] = useState('')
    const [openResult, setOpenResult] = useState(false)
    const [report, setReport] = useState({});
    const [labTests,setLabTests] = useState([]);
    const [resultDetails, setResultDetails] = useState({});
    const [resultParams,setParams] = useState([]);
    const [result_id, setResultId] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [isDeleting, setIsDeleting] = useState('idle')
    const [openDelete, setOpenDelete] = useState(false)
    const [deleteData, setDeleteData] = useState({})


    const {componentRef, handlePrint}  = usePrint();


    useEffect(()=>{
        if (!result_id){
            return;
        }
        const formData = new FormData();
        formData.append('test_results_id',result_id);
        axios.post(`${config.smsUrl}/claboratory/get_lab_results_details_by_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patient_info = !dt.patient_info ? {} : dt.patient_info;
            const parameters = !dt.params ? [] : dt.params;
            setResultDetails({...patient_info, visit_id});
            setParams(parameters)
        }).catch(err => {
            logoutErrorMessage(err,null,actions);
        })
        return ()=>{
            setResultDetails({})
            setParams([])
        }

    },[result_id])


    useEffect(()=>{
        if(!id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_lab_results_details_by_visit_id`, formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const rp = !dt.patient_info ? {} : dt.patient_info;
            const reportObj = {...rp,approved_first_name:rp.approved_firstname,approved_last_name:rp.approved_lastname, visit_id}
            const tests = !dt.lab_result ? [] : dt.lab_result;
            const arr = tests.map(item=>({...item, open:true}))
            setReport(reportObj);
            setLabTests(arr)
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[id]);

    const handleOpenItem = (index) =>{
        const openedArr = labTests.map((item, idx)=>{
            if(idx === index){
                return {...item, open:!item.open}
            }else {
                return item
            }

        })
        setLabTests(openedArr)
    }

    const isResolved = isSubmitted === 'resolved'
    const isPendingDelete = isDeleting === 'pending'
    const isResolvedDelete = isDeleting === 'resolved'

    const groupByLabRequestId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = formatDate(item.date_created);
            if(id in knowledge){
                result[knowledge[id]].tests.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: item.labrequest_id,
                    date: formatDate(item.date_created),
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    tests: [item]
                })

            }
        })
        return result
    }


    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_lab_request_by_visit_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = groupByLabRequestId(dt)
            const requests = arr.map(item=>({...item, open:true}))
            setStatus('success')
            setLabRequests(requests);
        }).catch(error => {
            setStatus('error')
            errorMessages(error, null, actions)
        });
    }, [isResolved, isResolvedDelete])

    const handleOpen = (id) =>{
        const itemsArr = labRequests.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setLabRequests(itemsArr)
    }

    const handleOpenReport  = (_id) =>{
        setId(_id)
        setOpenReport(true)
    }

    const handleCloseReport  = () =>{
        setOpenReport(false)
    }

    const handleOpenResult  = (test_results_id) =>{
        setResultId(test_results_id)
        setOpenResult(true)
    }

    const handleCloseResult  = () =>{
        setOpenResult(false)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false);
        }
    }

    const handleDeleteLabTest= (item) => {
        const params = {
            visit_id: +visit_id,
            result_type: 2,
            user_roles_id,
            ...item
        }
        setIsDeleting('pending');
        axios.post(`${config.smsUrl}/cdoctor/update_labrequest`, {...params}).then(() => {
            actions.snackbarActions.snackSuccess(`Lab test deleted successfully`);
            setIsDeleting('resolved');
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsDeleting('rejected');
        })

    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'



    const routes = doctorRoutes(patient_number, visit_id, patient_type, patient_admission_id)

    const {openBar, type, message} = snackbars;

    const deleteButtons = (
        <>
            <button type='button' onClick={()=>handleDeleteLabTest(deleteData)} disabled={isPendingDelete}
                    className='btn btn-sm small-btn sms-btn-dismiss mr-3'>{isPendingDelete ? 'Deleting...':'Delete'}</button>
            <button type='button' onClick={()=>setOpenDelete(false)} className='btn btn-sm small-btn sms-gray-btn mr-3'>Cancel</button>
        </>
    )
    return (
        <div className='journals'>
            <PageTitle title="Lab Request"/>
            <PageTitle title="Lab Request"/>
            <SubHeader title="Doctor" subTitle="Lab Request" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <DeleteDialog handleClose={()=>setOpenDelete(false)} title='Delete lab test'
                          openDialog={openDelete} message='delete' text='lab test'>
                {deleteButtons}
            </DeleteDialog>
            <CustomDialog title='Add Lab Request' open={openDialog} handleClose={handleCloseDialog} maxWidth='md'>
                <AddLabRequest {...{actions, visit_id,isSubmitted, setIsSubmitted, setOpenDialog,
                    isBlocking, setIsBlocking,isBlockDialog, setIsBlockDialog}}/>
            </CustomDialog>
            <CustomDialog open={openReport} handleClose={handleCloseReport} title='Lab Results' maxWidth='lg'>
                <RightAlignedContainer>
                    <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">
                        Print
                    </button>
                </RightAlignedContainer>
                <div ref={componentRef}>
                    <PatientLabReport data={report} labTests={labTests} handleOpenItem={handleOpenItem} labReport/>
                </div>
            </CustomDialog>

            <CustomDialog open={openResult} handleClose={handleCloseResult} title='Lab Results' maxWidth='lg'>
                <RightAlignedContainer>
                    <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">
                        Print
                    </button>
                </RightAlignedContainer>
                <div ref={componentRef}>
                    <LabResultsReport params={resultParams} resultDetails={resultDetails}/>
                </div>
            </CustomDialog>

            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={routes} patient_type={+patient_type}>
                <PatientInformation {...{patient_number, visit_id}}/>
                <Container>
                    <button onClick={()=>{
                        setOpenDialog(true)
                    }}  type='button' className='btn btn-sm sms-info-btn mb-2'>Add Lab Request</button>
                    {isLoading ? <CenteredContainer>
                        <LoadingGif/>
                    </CenteredContainer> : null}
                    {isSuccess ? labRequests.length > 0 ? labRequests.map((item)=>{
                        // const isAdded = item.tests.some(item=>item.item_status !== 'Done')
                        const isDone = item.tests.some(item=>item.item_status === 'Done')
                        const payStatus = item.tests.some(item=>item.pay_status === 0 || item.pay_status === 3)
                        return(
                            <CollapsibleList key={item.id} open={item.open} name={item.date} handleOpen={()=>handleOpen(item.id)} button={
                                <>
                                    {/*{payStatus || editTests.length > 0 ? <Tooltip title='Edit Request'>*/}
                                    {/*    <IconButton onClick={()=>handleOpenEdit(item)}>*/}
                                    {/*        <StyledIcon/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Tooltip> : null}*/}
                                    {isDone ?
                                        <ViewButton onClick={()=>handleOpenReport(item.id)} data-testId="view_lab_results" className='btn btn-sm sms-info-btn btn-sm'>View Results</ViewButton>
                                        : null}
                                </>
                            }
                                // user={ <ItemText  primary={`Requested By: ${item.doctor_name}`} />}
                            >
                                <ModuleTable headData={headData}>
                                    {item.tests.map((row, index) => {
                                        const isFinished = row.item_status === 'Done';
                                        const isPendingOutPatient = (row.status === 0 && row.pay_status === 0) || (row.pay_status === 3 && row.status === 1)
                                        const isPendingInPatient = row.status === 4 || isPendingOutPatient

                                        const isPending = patient_type === '1' ? isPendingOutPatient : isPendingInPatient

                                        const deleteObj = {
                                            hospital_test_id: row.hospital_test_id,
                                            labrequests_detail_id: row.labrequests_detail_id,
                                            bill_type:row.bill_type,
                                            bill_id:row.bill_id,
                                            bill_details:{
                                                service_id: row.service_id,
                                                quantity: 1,
                                                rate: row.cost,
                                                invoice_no: row.invoice_no ?? '',
                                                bill_id:row.bill_id,
                                            },
                                        }


                                        const outpatientStatus = {
                                            0:<ExpiredStatus>Unpaid</ExpiredStatus>,
                                            1:<PendingStatus>Results pending</PendingStatus>,
                                            2:<Processing>Samples collected</Processing>,
                                            3:<ActiveStatus>Results recorded</ActiveStatus>,
                                            8:<FinishedStatus>Results approved</FinishedStatus>,
                                            9:<ExpiredStatus>Declined</ExpiredStatus>
                                        }

                                        const inpatientStatus = {
                                            0:<ExpiredStatus>Unpaid(out-patient)</ExpiredStatus>,
                                            1:<PendingStatus>Results pending(out-patient)</PendingStatus>,
                                            3:<ActiveStatus>Results recorded(out-patient)</ActiveStatus>,
                                            4:<ExpiredStatus>Results Pending</ExpiredStatus>,
                                            5:<Processing>Samples collected</Processing>,
                                            6:<ActiveStatus>Results recorded</ActiveStatus>,
                                            8:<FinishedStatus>Results approved</FinishedStatus>,
                                            9:<ExpiredStatus>Declined</ExpiredStatus>
                                        }
                                        const lab_status ={
                                            '1': outpatientStatus[row.status],
                                            '2':inpatientStatus[row.status]
                                        }
                                        return(
                                            <TableRow
                                                key={index}
                                            >
                                                <DataCell>
                                                    {row.hospital_test_name}
                                                </DataCell>
                                                <DataCell>{row.hospital_test_type}</DataCell>
                                                <DataCell>
                                                    {lab_status[patient_type]}
                                                </DataCell>
                                                <DataCell>
                                                    {isFinished ?
                                                        <Tooltip title='View Results'>
                                                            <IconButton onClick={()=>handleOpenResult(row.test_results_id)}>
                                                                <StyledLibraryIcon/>
                                                            </IconButton>
                                                        </Tooltip> : null}
                                                    {isPending  ?  <Tooltip title='Delete lab test'>
                                                        <IconButton onClick={() => {
                                                            setOpenDelete(true)
                                                            setDeleteData({
                                                                labrequest_id:row.labrequest_id,
                                                                invoices_added: [], invoices_removed: [deleteObj]
                                                            })
                                                        }}>
                                                            <StyledRemoveIcon/>
                                                        </IconButton>
                                                    </Tooltip>: null}
                                                </DataCell>
                                            </TableRow>
                                        )})}
                                </ModuleTable>
                            </CollapsibleList>
                        )
                    }) : <CenteredContainer>
                        <P>No lab tests requested for patient, click the add request button to request lab tests for patient</P>
                    </CenteredContainer>:null}
                    {isError ? <CenteredContainer>
                        <P>An error occurred on the server</P>
                    </CenteredContainer>:null}
                </Container>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GeneralLabRequests);