import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {titleCase} from "../Users/addUser";
import {useTheme} from "@material-ui/core/styles";
import {dateTimeConvert, formatDateTime} from "../Utils/ConvertDate";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import styled from "@emotion/styled/macro";
import * as colors from "../../styles/colors.js"
import { ErrorMessage } from "../Utils/styledComponents";
import TextField from "../Utils/FormInputs/TextField";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {history} from "../../Helpers/history";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import CustomDialog from "../Utils/Dialogs/CustomDialog";


const variants = {
    selected: {
        background: colors.blue001,
        color: colors.base,
       
      },
      primary: {
        background: colors.gray,
        color: colors.text,
      },
  }

const StyledDiv = styled.div({
    padding:'5.45px 10px',
    borderRadius:'5px',
    fontSize:'12.11px',
    textTransform:'uppercase',
    textAlign:'center',
    cursor:'pointer'
  },({variant})=>variants[variant])

const StyledColumn = styled.div({
    paddingBottom:'12px',
})


const EnterResults = ({openDialog, setOpenDialog,isSubmitted, setIsSubmitted, actions, ids}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const technician = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const [resultState, setResultState] = useState({date: dateTimeConvert(), report: '', observations: ''});
    const [patient, setPatient] = useState({});
    const [parameters, setParameters] = useState([]);
    const [patientDetails, setPatientDetails] = useState({});
    const [tests, setTests] = useState([]);
    const [submitted, setSubmittedResult] = useState(false);
    const [sample_type, setSampleType] = useState('')
    const [sampleTypes, setSampleTypes] = useState([]);
    const [approvedBy,setApprovedBy] = useState('')
    const [labTests, setLabTests] = useState([])
    const [test_id, setTestId] = useState({sample_id:'',labrequests_detail_id:''})
    const isFetching = isSubmitted === 'pending';
    const [status, setStatus] = useState('idle')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const [visitDetails, setVisitDetails] = useState({})

    useEffect(() => {
        if(!ids?.visit_id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', ids?.visit_id);
        axios.post(`${config.smsUrl}/claboratory/get_collected_sample_details_by_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const pt = !dt.patient_info ? {} : dt.patient_info;
            const param = !dt.params ? [] : dt.params;
            setPatientDetails(pt);
            // setTests(param)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [ids?.visit_id]);

    useEffect(() => {
        if(!(ids?.visit_id && ids?.labrequest_id)){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', ids?.visit_id);
        formData.append('labrequest_id', ids?.labrequest_id);
        axios.post(`${config.smsUrl}/claboratory/get_samples_saved_per_lab_request_by_id`,formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const samArr = dt.params ? dt.params : [];
            const arr = samArr.map(item => ({
                value: item.sample_type,
                label: item.type_name
            }))
            setSampleTypes(arr);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [ids?.visit_id, ids?.labrequest_id]);



    useEffect(() => {
        setPatient({
            ...patientDetails,
            patient_number: patientDetails.patient_number,
            patient_name: `${!patientDetails.patient_firstname ? "" : patientDetails.patient_firstname} 
            ${!patientDetails.patient_lastname ? "" : patientDetails.patient_lastname}`,
            dob: patientDetails.age,
            gender: patientDetails.gender,
            result_type: patientDetails.result_type ? patientDetails.result_type : '',
            bill_no: patientDetails.transaction_id,
            sample_no: patientDetails.sample_id,
            sample_description: patientDetails.sample_description,
            lab_test: patientDetails.hospital_test_name,
            drawn_date: patientDetails.time_taken,
            patient_type:patientDetails.patient_type,
            drawn_by: `${!patientDetails.tech_firstname ? "" : patientDetails.tech_firstname} ${!patientDetails.tech_lastname ? "" : patientDetails.tech_lastname}`
        });
    }, [patientDetails, tests]);

    const handleChangeSampleType = (value) =>{
        setSampleType(value)
        const {patient_type} = patient;
        const formData = new FormData();
        formData.append('visit_id', ids?.visit_id);
        formData.append('sample_type_id', value?.value ? value?.value : '');
        formData.append('patient_type', patient_type);
        axios.post(`${config.smsUrl}/claboratory/get_sample_type_tests`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const param = !dt.lab_tests ? [] : dt.lab_tests;
            const arr = param.map(item=>({
                ...item, isSelected:false
            }))
            setLabTests(arr)
            setStatus('sample_selected')
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
        setIsBlocking(true)
    }

    // const handleChangeApprovedBy = (value) => {
    //     setApprovedBy(value);
    //     setIsBlocking(true);
    // }


    const handleSelectTest = (hospital_test_id, index) => {
        const arr = labTests.map((item, idx)=>{
            if(idx ===  index){
                const formData = new FormData();
                formData.append('hospital_test_id', hospital_test_id);
                axios.post(`${config.smsUrl}/claboratory/get_labtests_parameters`, formData).then(res => {
                    const data = res.data;
                    const dt = !data ? [] : data;
                    const paramsArr = dt.map(item=>({
                        ...item,test_value:''
                    }))
                    setParameters(paramsArr)
                    setStatus('test_selected')
                }).catch(err => {
                    logoutErrorMessage(err, null, actions)
                })
                return {...item, isSelected:!item.isSelected}
            }else{
                return {...item, isSelected:false}
            }
        })
        const selectedTest = arr.filter(item=>item.isSelected);
        const [test] = selectedTest;
        const obj = test ? test : {}
        const sample = obj.sample_id ? obj.sample_id : '';
        const test_detail_id = obj.labrequests_detail_id ? obj.labrequests_detail_id : '';
        setTestId({sample_id:sample,labrequests_detail_id:test_detail_id})
        setLabTests(arr)
        setIsBlocking(true)

    };


    const handleChangeResult = (event, idx) => {
        const arr = parameters.map((item, index)=>{
            if(index === idx){
                return {...item,test_value:event.target.value}
            }
            return item
        })
        setParameters(arr)
        setIsBlocking(true)
    };

    const handleChangeResultState = (event) => {
        const {value, name} = event.target;
        setResultState({...resultState, [name]: value})
        setIsBlocking(true)
    };

    const handleChangeQualitative = (val, idx) => {
        const arr = parameters.map((item, index)=>{
            if(index === idx){
                return {...item,test_value:val}
            }
            return item
        })
        setParameters(arr)
        setIsBlocking(true)
    }


    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false)
            setParameters([])
            setStatus('idle')
            setLabTests([])
            setSampleType('')
            setApprovedBy('')
            setResultState({...resultState,report:''})
        }

    }

    const handleSubmitResult = (event) => {
        event.preventDefault();
        const {report} = resultState;
        const {patient_type} = patient
        const formData = new FormData();
        const {result_type} = patient;
        const {sample_id, labrequests_detail_id} = test_id;

        const obj = parameters[0] ? parameters[0] : {};
        formData.append('visit_id',ids?.visit_id);
        formData.append('labrequest_id',ids?.labrequest_id);
        formData.append('activity_id',ids?.activity_id ? ids?.activity_id : '');
        formData.append('labrequests_detail_id',labrequests_detail_id)
        formData.append('user_roles_id', user_roles_id);
        // formData.append('approvedBy_id', approvedBy.value);
        formData.append('approvedBy', approvedBy.label);
        formData.append('result_type', result_type);
        formData.append('notes', report ? report : '');
        formData.append('sample_id', sample_id);
        formData.append('patient_type', patient_type);
        formData.append('lab_state', 3);
        formData.append('is_external', obj.is_external);
        setIsBlocking(false)
        parameters.filter(test=>test.hospital_test_detail_id).forEach(item => {
            const isReactive = item.sub_test_name === 'reactive/non-reactive';
            const isBlood  = item.sub_test_name === 'blood_group';
            const isPositive = item.sub_test_name === '+ve/-ve';
            const value = (isBlood || isReactive || isPositive) ? item.test_value.value : item.test_value;
            formData.append('test_value', value);
            formData.append('service_id', item.service_id);
            formData.append('hospital_test_detail_id', item.hospital_test_detail_id);
        });
        setSubmittedResult(true);
        const arr = parameters.length > 0 && parameters.every(test=>test.hospital_test_detail_id && test.service_id && (test.test_value.value || test.test_value))
        if (arr && sample_type){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/claboratory/save_lab_results`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Lab results saved successfully');
                history.push('/pendingsamples')
                setIsSubmitted('resolved');
                setSubmittedResult(false)
                setParameters([])
                handleChangeSampleType(sample_type)
                setResultState({...resultState,report:''})
                // setSampleType('')
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }

    };

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false)
        setParameters([])
        setStatus('idle')
        setLabTests([])
        setSampleType('')
        setApprovedBy('')
        setResultState({...resultState,report:''})
    }

    const isTestIdle = status === 'idle';
    const isSampleSelected = status === 'sample_selected';
    const isTestSelected = status === 'test_selected'


    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;



    const {patient_number, patient_name, dob, gender,drawn_date, drawn_by, doctor_firstname, doctor_lastname} = patient;
    const {date, report} = resultState;

    const reactive = [{value: 3, label: 'Reactive'}, {value: 2, label: 'Non-reactive'}];

    const blood_g = [
        {label: "A+", value: "A+"},
        {label: "A-", value: "A-"},
        {label: "B+", value: "B+"},
        {label: "B-", value: "B-"},
        {label: "O+", value: "O+"},
        {label: "O-", value: "O-"},
        {label: "AB+", value: "AB+"},
        {label: "AB-", value: "AB-"},
    ]
    const positive = [{value: 1, label: 'Positive'}, {value: 0, label: 'Negative'}];

    const doctor_name =  `${!doctor_firstname ? "" : doctor_firstname} ${!doctor_lastname ? "" : doctor_lastname}`


    useEffect(()=>{
        if(!ids?.visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${ids?.visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[ids?.visit_id]);


    return (
        <CustomDialog title='Record Results' open={openDialog} handleClose={handleCloseDialog} maxWidth="md"
                      isContentOverflow={false} isPaperStyle>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={() => {
                    return isBlocking;
                }}
                navigate={path => history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
         <form autoComplete="off" onSubmit={handleSubmitResult}>

            <div className="lab-patient-details mb-3">
                <table className="table-sm table-borderless table prescription-table">
                    <tr>
                        <td><p>Patient ID: <span className="ml-2"><strong>{patient_number}</strong></span></p></td>
                        <td><p>Requested by:<span
                            className="ml-2"><strong>{doctor_name}</strong></span>
                        </p></td>
                    </tr>
                    <tr>
                        <td><p>Patient Name: <span className="ml-2"><strong>{patient_name}</strong></span></p></td>
                        <td><p>Date Requested:<span
                            className="ml-2"><strong>{drawn_date ? formatDateTime(drawn_date) : ''}</strong></span>
                        </p></td>
                    </tr>
                    <tr>
                        <td><p>Age: <span className="ml-2"><strong>{dob}</strong></span></p></td>
                        <td><p>Drawn By: <span className="ml-2"><strong>{drawn_by}</strong></span></p></td>
                    </tr>
                    <tr>
                        <td><p>Gender: <span
                            className="ml-2"><strong>{gender ? titleCase(gender) : ''}</strong></span></p></td>
                        <td><p>Drawn Date:<span
                            className="ml-2"><strong>{drawn_date ? formatDateTime(drawn_date) : ''}</strong></span>
                        </p></td>
                    </tr>
                    <tr>
                        <td><span>Payment Mode: </span> <span
                        ><strong>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ? 'CASH' : ''}</strong></span>
                        </td>
                        {visitDetails.is_on_insurance === 1 ?
                            <td><span>Provider Name: </span>
                                <span><strong>{visitDetails?.insurance_provider_name}</strong></span></td> : <td/>}
                    </tr>
                    <tr>
                        {visitDetails.is_on_insurance === 1 ?
                            <td><span>Account Name: </span> <span><strong>{visitDetails?.account_name}</strong></span>
                            </td> : <td/>}
                        <td/>
                    </tr>
                </table>
            </div>
             <div className="mb-3">
                 <div className="row">
                     <div className="col-md-6">
                         <Form.Group as={Row}>
                             <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                 <Label name="Sample" htmlFor='sample' type/>
                             </Col>
                             <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                 <CustomSelect submitted={submitted} dataTest="collected_sample" value={sample_type}
                                               id='collected_sample'
                                               onChange={handleChangeSampleType}
                                               options={sampleTypes}/>
                                 {(submitted && !sample_type) && <ErrorMessage>This field is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Report" htmlFor='report'  />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <ResizableTextarea rows={2} placeholder="Write comment..." onChange={handleChangeResultState} id="report" name="report" value={report}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Lab Tests"  type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <div className='row'>
                                {labTests.map((item, index)=>(
                                    <StyledColumn key={index}  className='col-lg-6'>
                                        <StyledDiv data-testid={`hospital_test_name-${index}`} onClick={()=>handleSelectTest(item.hospital_test_id, index)} variant={item.isSelected ? 'selected':'primary'}>{item.hospital_test_name}</StyledDiv>
                                    </StyledColumn>
                                ))}
                            </div>
                            </Col>
                        </Form.Group>
                        
                    </div>
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                            <Label name="Date & Time"  htmlFor="date" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField onChange={handleChangeResultState} name="date" id="date"
                                    value={date} type="datetime-local"/>
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Technician" htmlFor='technician' />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type="text" disabled={true} id="technician" value={titleCase(technician)}/>
                            </Col>
                        </Form.Group>  
                        {/*<Form.Group as={Row}>*/}
                        {/*<Col xs={12} sm={12} md={12} lg={3} xl={2}>*/}
                        {/*        <Label name="Approved By" htmlFor='approval'  type/>*/}
                        {/*    </Col>*/}
                        {/*    <Col xs={12} sm={12} md={12} lg={7} xl={7}>*/}
                        {/*    <CustomSelect*/}
                        {/*        submitted={submitted}*/}
                        {/*        dataTest="approved_by"*/}
                        {/*        value={approvedBy}*/}
                        {/*        id='approvedBy'*/}
                        {/*        onChange={handleChangeApprovedBy}*/}
                        {/*        options={users} // This should be the array of objects with "value" and "label" properties*/}
                        {/*        />*/}

                        {/*        {(submitted && !sample_type) && <ErrorMessage>This field is required</ErrorMessage>}*/}
                        {/*    </Col>*/}
                        {/*</Form.Group>      */}
                    </div>
                </div>
                
                    <table className="table table-sm table-borderless">
                        <thead>
                            <tr style={{borderTop: "1px solid #909090"}}>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><strong>Code</strong>
                                </td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><strong>Sub Test Name</strong>
                                </td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><strong>Status</strong>
                                </td>
                                <td  className={palette.type === 'dark' && 'dark-theme-color'}
                                    align="center"><strong>Result</strong><span className="help-block">*</span>
                                </td>
                                    <td  className={palette.type === 'dark' && 'dark-theme-color'}
                                    align="center"><strong>Reference Ranges</strong>
                                </td>
                                <td  className={palette.type === 'dark' && 'dark-theme-color'}
                                    align="center"><strong>Unit</strong>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {isTestIdle ? <tr><td colSpan={5} align="center" style={{color:'red'}}>Select sample to load lab tests</td></tr>:null} 
                            {isSampleSelected ? <tr><td colSpan={5} align="center" style={{color:'red'}}>Click on lab test to load  test parameters</td></tr>:null} 
                            {isTestSelected ? parameters.length > 0 ? parameters.map((item, index) =>{
                                const isReactive = item.sub_test_name === 'reactive/non-reactive';
                                const isBlood  = item.sub_test_name === 'blood_group';
                                const isPositive = item.sub_test_name === '+ve/-ve';
                                const isReferenceRange = item.reference_range
                                return(

                                <tr key={index} style={{borderTop: "1px solid #909090",}}>
                                    <td 
                                        className={palette.type === 'dark' && 'dark-theme-color'}> {item.sub_test_code && item.sub_test_code !== 'null' ? item.sub_test_code : 'N/A'}
                                    </td>
                                    <td 
                                        className={palette.type === 'dark' && 'dark-theme-color'}> {(item.sub_test_name && item.sub_test_name !== '+ve/-ve' )? item.sub_test_name :
                                        (item.sub_test_name === '+ve/-ve' || item.sub_test_name === '') ?  'Result':'N/A'}
                                    </td>
                                    <td>  {item.is_external=== true ? 'External Test': item.is_external=== false ?  'Internal Test' : ''}</td>
                                    <td  align="center">
                                        {(isReactive || isBlood || isPositive) ? 
                                        <>
                                        <CustomSelect submitted={submitted}  value={item.test_value}
                                                    onChange={val=>handleChangeQualitative(val, index)}
                                                    options={isPositive ? positive :
                                                        isBlood ? blood_g : isReactive ?
                                                            reactive : []}  placement={isBlood ? "top":"bottom"} id='qualitative-results'/>
                                        {(submitted && !item.test_value) && <ErrorMessage>Result is required</ErrorMessage>}
                                        </>:
                                        isReferenceRange ? <>
                                            <TextField submitted={submitted} type="text" value={item.test_value}
                                                onChange={e => handleChangeResult(e, index)} placeholder="Enter results..."/>
                                                {(submitted && !item.test_value) && <ErrorMessage>Result is required</ErrorMessage>}
                                        </> :
                                        <>
                                            <ResizableTextarea submitted={submitted} type="text" value={item.test_value}
                                                    onChange={e => handleChangeResult(e, index)} rows={3} placeholder="Enter results..."/>
                                            {(submitted && !item.test_value) && <ErrorMessage>Result is required</ErrorMessage>}
                                        </>}
                                    </td>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}
                                        align="center">{(item.reference_range && item.reference_range !== 'null'  ) ?
                                        item.reference_range : 'N/A'} </td>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}
                                        align="center">{item.unit && item.unit !== 'null' ? item.unit : 'N/A'}</td>
                                </tr>
                            )}):<tr><td colSpan={5} align="center">No available parameters for this lab test</td></tr>:null}
                            {isTestSelected && labTests.length === 0 ? <tr><td colSpan={5} align="center">No lab tests availabe for this sample, select another sample</td></tr>:null}
                        </tbody>
                    </table>
                
            </div>
                <button type="submit" id="submit" className="btn btn-sm sms-btn px-4" disabled={isSubmitted === 'pending'}>
                    {isFetching ? "Saving..." : "Save"}
                </button>
            </form>
        </CustomDialog>
         
    )
};


export default EnterResults;
