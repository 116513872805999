import React, {useEffect, useState} from "react";
import {dateTimeConvert, formatDateTime} from "../Utils/ConvertDate";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {titleCase} from "../Users/addUser";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import Textarea from  "../Utils/FormInputs/Textarea"
import { ErrorMessage } from "../Utils/styledComponents";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import TextField from "../Utils/FormInputs/TextField";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../Helpers/history";
import CustomDialog from "../Utils/Dialogs/CustomDialog";


const CollectSample = ({ids, actions,openDialog, setOpenDialog,isSubmitted, setIsSubmitted}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const [sampleState, setSampleState] = useState({drawn_date: dateTimeConvert()});
    const [patient, setPatient] = useState({
        patient_id: '', patient_name: '', age: '',
        gender: '', bill_no: '', payment_status: '', clinic: '', requested_by: '', lab_test: '',
        lab: '', time_requested: '', patient_type:''
    });
    const [openSampleNo, setOpenSampleNo] = useState(false);
    const [sample_no, setSampleNo] = useState([]);
    const [submittedSample, setSubmittedSample] = useState(false)
    const [sampleTypes, setSampleTypes] = useState([]);
    const [labTests, setLabTests] = useState([])
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const isFetching = isSubmitted === 'pending';
    const [visitDetails, setVisitDetails] = useState({})


    const {drawn_date} = sampleState;
    const {patient_id, patient_name, age, gender,  requested_by,  time_requested} = patient;

    useEffect(() => {
        axios.get(`${config.smsUrl}/claboratory/get_all_sample_types`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setSampleTypes(dt);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        if(!ids?.labrequest_id){
            return;
        }
        const formData = new FormData();
        formData.append('labrequest_id', ids?.labrequest_id);
        axios.post(`${config.smsUrl}/claboratory/get_patient_info`, formData).then(res => {
            const data = res.data;
            const info = !data ? {} : data;
            const tests  = info.lab_info ? info.lab_info : []
            const patientInfo = tests[0] ? tests[0] : {}
            const labs = tests.map(item=>({
                ...item,
                sample_type:'',
                sample_description:'',
                add_to_invoice: false
            }))
            setLabTests(labs)
            setPatient({
                patient_id: patientInfo.patient_number,
                patient_name: `${!patientInfo.patient_firstname ? "" : patientInfo.patient_firstname} 
            ${!patientInfo.patient_lastname ? "" : patientInfo.patient_lastname}`,
                age: patientInfo.age,
                gender: patientInfo.gender,
                bill_no: patientInfo.transaction_id,
                clinic: patientInfo.department_name,
                requested_by: `${!patientInfo.doctor_firstname ? "" : patientInfo.doctor_firstname} 
            ${!patientInfo.doctor_lastname ? "" : patientInfo.doctor_lastname}`,
                lab_test: patientInfo.hospital_test_name,
                time_requested: patientInfo.time_requested,
                patient_type : patientInfo.patient_type
            })
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [ids?.labrequest_id]);

    useEffect(()=>{
        if(!ids?.visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${ids?.visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[ids?.visit_id]);

    const closeSampleNo = () => {
        setOpenSampleNo(false);
        setOpenDialog(false)
    };

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
            setOpenDialog(false)
        }else{
            setOpenDialog(false)
            setSampleNo([])
            setIsBlockDialog(false)
            setIsBlocking(false)
        }

    }

    const handleChangeSampleTypes = (value, _id) => {
        const arr = labTests.map(item=>{
            if(item.labrequests_detail_id === _id){
                return {...item, sample_type:value}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };

    const handleChangeSampleDescription = (event, _id) => {
        const arr = labTests.map(item=>{
            if(item.labrequests_detail_id === _id){
                return {...item, sample_description:event.target.value}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };

    const handleChangeAddToInvoice = (event, _id) => {
        const arr = labTests.map(item=>{
            if(item.labrequests_detail_id === _id){
                return {...item, add_to_invoice: event.target.checked}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };

    const handleChangeSampleState = (event) => {
        const {name, value} = event.target;
        setSampleState({...sampleState, [name]: value})
        setIsBlocking(true)
    };

    const handleSubmitSample = (event) => {
        event.preventDefault();
        const {patient_type} = patient
        const formData = new FormData();
        labTests.forEach(item=>{
            const sample_type = item.sample_type ? item.sample_type:''
            formData.append('sample_type', sample_type.value);
            formData.append('service_id', item.service_id);
            formData.append('sample_description', item.sample_description);
            formData.append('labrequests_detail_id', item.labrequests_detail_id);
            formData.append('add_to_invoice', item.add_to_invoice ? '1' : '0');
        })

        formData.append('requested_by', user_roles_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('visit_id',ids?.visit_id)
        formData.append('labrequest_id', ids?.labrequest_id);
        formData.append('activity_id', ids?.activity_id ? ids?.visit_id : '');
        formData.append('patient_type', patient?.patient_type);
        formData.append('lab_state', 2);
        formData.append('take_up_time ', ids?.current_date ? ids?.current_date : '');
        const arr = labTests.every(item=>item.sample_type.value);
        setSubmittedSample(true);
        setIsBlocking(false)
        if (arr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/claboratory/save_sample`, formData).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                setSampleNo(dt);
                actions.snackbarActions.snackSuccess('Sample registered successfully');
                setIsSubmitted('resolved');
                setSubmittedSample(false)
                const cleared = labTests.map((item)=>({
                    ...item,sample_type:'',sample_description:''
                }))
                setLabTests(cleared)
                setOpenDialog(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }

    };

    const handleResetForm  = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const cleared = labTests.map((item)=>({
            ...item,sample_type:'',sample_description:'', add_to_invoice: false
        }))
        setLabTests(cleared)
    }

    const sample_numbers = (
        <table className="table table-sm table-bordered billing-invoice-table">
            <thead>
                <tr>
                    <td><strong>Test</strong></td>
                    <td><strong>Sample No.</strong></td>
                </tr>
            </thead>
            <tbody>
                {sample_no.map(item=>(
                    <tr key={item.sample_no}>
                        <td>{item.hospital_test_name}</td>
                        <td>{item.sample_no}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
    return (
        <CustomDialog open={openDialog} handleClose={handleCloseDialog} maxWidth="md"
                      isContentOverflow={false} isPaperStyle>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <PrintDialog handleClose={closeSampleNo} openDialog={openSampleNo} text={sample_numbers} message="Sample No(s)."
                         sample/>
            <form autoComplete="off" onSubmit={handleSubmitSample}>
                <div className="lab-patient-details mb-3">
                    <table className="table table-borderless table-sm prescription-table">
                        <tr>
                            <td><p>Patient ID: <span className="ml-2"><strong>{patient_id}</strong></span></p></td>
                            <td><p>Request by:<span
                                className="ml-2"><strong>{requested_by ? titleCase(requested_by) : ''}</strong></span>
                            </p></td>
                        </tr>
                        <tr>
                            <td><p>Patient Name: <span
                                className="ml-2"><strong>{titleCase(!patient_name ? "" : patient_name)}</strong></span>
                            </p></td>
                            <td><p>Date Requested:<span
                                className="ml-2"><strong>{time_requested ? formatDateTime(time_requested) : ''}</strong></span>
                            </p></td>
                        </tr>
                        <tr>
                            <td><p>Age: <span className="ml-2"><strong>{!age ? "" : age}</strong></span></p></td>
                            <td><p>Gender: <span className="ml-2"><strong>
                                {titleCase(!gender ? "" : gender)}</strong></span></p></td>
                        </tr>
                        <tr>
                            <td><span>Payment Mode: </span> <span
                            ><strong>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ? 'CASH' : ''}</strong></span>
                            </td>
                            {visitDetails.is_on_insurance === 1 ?
                                <td><span>Provider Name: </span> <span><strong>{visitDetails?.insurance_provider_name}</strong></span></td> : <td/>}
                        </tr>
                        <tr>
                            {visitDetails.is_on_insurance === 1 ?
                                <td><span>Account Name: </span> <span><strong>{visitDetails?.account_name}</strong></span></td> : <td/>}
                            <td/>
                        </tr>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Date & Time"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField onChange={handleChangeSampleState} name="drawn_date"
                                           value={drawn_date}
                                           type="datetime-local"/>
                            </Col>
                        </Form.Group>

                    </div>
                </div>
            
                <table className="table table-sm table-bordered billing-invoice-table procedure-table">
                    <thead>
                    <tr>
                        <th><strong>Lab Test</strong></th>
                        <th><strong>Lab</strong></th>
                        <th><strong>Status</strong></th>
                        <th><strong>Sample Type</strong></th>
                        <th><strong>Sample Description</strong></th>
                        {visitDetails.is_on_insurance === 1 ? <th><strong>Add to Invoice</strong></th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    {labTests.map((item, index)=>(
                            <tr key={item.labrequests_detail_id}>
                                <td>{item.hospital_test_name}</td>
                                <td>{item.hospital_test_type}</td>
                                <td>{item.is_external === true ? 'External Test' : item.is_external === false ? 'Internal Test' : ''}</td>
                                <td>
                                    <CustomSelect submitted={submittedSample} value={item.sample_type}
                                                  onChange={(value) => handleChangeSampleTypes(value, item.labrequests_detail_id)}
                                                  options={sampleTypes.map(item => ({
                                                      value: item.type_id,
                                                      label: item.type_name
                                                  }))} id={`sample_${index}`}/>
                                    {(submittedSample && !item.sample_type) &&
                                        <ErrorMessage>Sample type is required</ErrorMessage>}
                                </td>
                                <td>
                                    <Textarea name="sample_description" value={item.sample_description}
                                              onChange={(e) => handleChangeSampleDescription(e, item.labrequests_detail_id)}/>
                                </td>
                                {visitDetails.is_on_insurance === 1 ? <td><input type="checkbox" name="add_to_invoice"
                                           onChange={(e) => handleChangeAddToInvoice(e, item.labrequests_detail_id)}
                                           />
                                </td> : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="submit" id="submit" disabled={isFetching}
                        className="btn btn-sm sms-btn px-4">{isFetching ? "Saving..." : "Save"}</button>
            </form>
        </CustomDialog>
    )
};

export default CollectSample;
