import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {titleCase} from "../Users/addUser";
import {useTheme} from "@material-ui/core/styles";
import {dateTimeConvert, formatDateTime} from "../Utils/ConvertDate";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../Helpers/history";


const EditLabResults = ({openDialog, setOpenDialog, ids, actions,isSubmitted, setIsSubmitted}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const technician = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const [resultState, setResultState] = useState({date: dateTimeConvert(), report: '', observations: '',approvedBy:''});
    const [patient, setPatient] = useState({
        patient_number: '', patient_name: '', dob: '', gender: '', bill_no: '', result_type: '',
        sample_no: '', sample_description: '', lab_test: '', drawn_date: '', drawn_by: '',patient_type:''
    });
    const [parameters, setParameters] = useState([{
        hospital_detail_id: '',
        parameter_name: '',
        result: '',
        unit: '',
        min_range: '',
        max_range: ''
    }]);
    const [submitted, setSubmittedResult] = useState(false);
    // const [updateDetails, setUpdateDetails] = useState({});
    // const [paramDetails, setParamDetails] = useState([]);
    const [result, setResult] = useState('')
    const [q_type, setQtype] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const isFetching = isSubmitted === 'pending';

    useEffect(() => {
        if(!(ids?.test_results_id && ids?.sample_id)){
            return
        }
        const formData = new FormData();
        formData.append('test_results_id', ids?.test_results_id);
        formData.append('sample_id', ids?.sample_id);
        axios.post(`${config.smsUrl}/claboratory/lab_results_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const updateDetails = !dt.patient_info ? {} : dt.patient_info;
            const paramDetails = !dt.result_info ? [] : dt.result_info;
            const {result_type} = updateDetails;

            setPatient({
                patient_number: updateDetails.patient_number,
                patient_name: `${!updateDetails.patient_firstname ? "" : updateDetails.patient_firstname} 
            ${!updateDetails.patient_lastname ? "" : updateDetails.patient_lastname}`,
                dob: updateDetails.age,
                gender: updateDetails.gender,
                bill_no: updateDetails.transaction_id,
                result_type: updateDetails.result_type ? updateDetails.result_type : '',
                sample_no: updateDetails.sample_id,
                sample_description: updateDetails.sample_description,
                lab_test: updateDetails.hospital_test_name,
                drawn_date: updateDetails.time_taken,
                drawn_by: `${!updateDetails.tech_firstname ? "" : updateDetails.tech_firstname} 
            ${!updateDetails.tech_lastname ? "" : updateDetails.tech_lastname}`,
                patient_type:updateDetails.patient_type
            });

            const all_params = paramDetails.map(item => ({
                ...item,
                hospital_detail_id: item.hospital_test_detail_id,
                parameter_name: item.sub_test_name,
                unit: item.unit,
                min_range: item.critical_low,
                max_range: item.critical_high,
                result: item.test_value
            }));

            const param = paramDetails ? paramDetails : []
            const para = param[0] ? param[0] : {};
            const val = para.sub_test_name ? para.sub_test_name : '';
            const result_data = para.test_value ? para.test_value : '';
            const notes = para.notes ? para.notes : ''

            if (result_type === 3) {
                setResultState({...resultState, observations: result_data})
            }
            const r = result_data === '0' ? 'Negative' : result_data === '1' ? 'Positive' :
                result_data === '3' ? 'Reactive' : result_data === '2' ? 'Non-reactive' : ''
            if (result_type === 1) {
                setResult({value: result_data, label: val === 'blood_group' ? result_data : r})
            }
            setResultState({...resultState,report: notes,observations: result_data})
            setParameters(all_params)
            setQtype(val)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [ids?.test_results_id, ids?.sample_id]);

    // useEffect(() => {
    //     if (!(updateDetails && paramDetails.length  > 0)){
    //         return
    //     }
    //     const {result_type} = updateDetails;
    //     setPatient({
    //         patient_number: updateDetails.patient_number,
    //         patient_name: `${!updateDetails.patient_firstname ? "" : updateDetails.patient_firstname}
    //         ${!updateDetails.patient_lastname ? "" : updateDetails.patient_lastname}`,
    //         dob: updateDetails.age,
    //         gender: updateDetails.gender,
    //         bill_no: updateDetails.transaction_id,
    //         result_type: updateDetails.result_type ? updateDetails.result_type : '',
    //         sample_no: updateDetails.sample_id,
    //         sample_description: updateDetails.sample_description,
    //         lab_test: updateDetails.hospital_test_name,
    //         drawn_date: updateDetails.time_taken,
    //         drawn_by: `${!updateDetails.tech_firstname ? "" : updateDetails.tech_firstname}
    //         ${!updateDetails.tech_lastname ? "" : updateDetails.tech_lastname}`,
    //         patient_type:updateDetails.patient_type
    //     });
    //
    //     const all_params = paramDetails.map(item => ({
    //         ...item,
    //         hospital_detail_id: item.hospital_test_detail_id,
    //         parameter_name: item.sub_test_name,
    //         unit: item.unit,
    //         min_range: item.critical_low,
    //         max_range: item.critical_high,
    //         result: item.test_value
    //     }));
    //
    //     const param = paramDetails ? paramDetails : []
    //     const para = param[0] ? param[0] : {};
    //     const val = para.sub_test_name ? para.sub_test_name : '';
    //     const result_data = para.test_value ? para.test_value : '';
    //     const notes = para.notes ? para.notes : ''
    //
    //     if (result_type === 3) {
    //
    //         setResultState({...resultState, observations: result_data})
    //     }
    //     const r = result === '0' ? 'Negative' : result === '1' ? 'Positive' :
    //         result === '3' ? 'Reactive' : result === '2' ? 'Non-reactive' : ''
    //     if (result_type === 1) {
    //         setResult({value: result_data, label: val === 'blood_group' ? result_data : r})
    //     }
    //     setResultState({...resultState,report: notes,observations: result_data})
    //     setParameters(all_params)
    //     setQtype(val)
    // }, [updateDetails, paramDetails]);

    const handleChangeResult = (event, idx) => {
        const paramsCopy = [...parameters];
        if (paramsCopy[idx]) {
            paramsCopy[idx].result = event.target.value;
        }
        setParameters(paramsCopy)
        setIsBlocking(true)
    };

    const handleChangeResultState = (event) => {
        const {value, name} = event.target;
        setResultState({...resultState, [name]: value})
        setIsBlocking(true)
    };

    const handleChangeQualitative = (val) => {
        setResult(val)
        setIsBlocking(true)
    }


    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false)
        }

    }

    const handleSubmitResult = (event) => {
        event.preventDefault();
        const {result_type, patient_type} = patient
        const {observations, report} = resultState
        const formData = new FormData();
        const obj = parameters[0] ? parameters[0] : {};
        formData.append('visit_id', ids?.visit_id);
        formData.append('test_results_id', ids?.test_results_id);
        formData.append('labrequests_detail_id', ids?.labrequests_detail_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('notes', report);
        formData.append('patient_type', patient_type);
        formData.append('is_external', obj.is_external);
        if (result_type === 1 || result_type === 3) {
            formData.append('test_value', result_type === 1 ? result.value : result_type === 3 ? observations : '')
        }
        parameters.filter(test=>test.hospital_detail_id).forEach(item => {
            result_type === 2 && formData.append('test_value', item.result);
            formData.append('hospital_test_detail_id', item.hospital_detail_id);
        });
        setSubmittedResult(true);
        setIsBlocking(false)
        const arr = parameters.every(test=>test.hospital_detail_id && test.result)
        if(arr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/claboratory/edit_lab_results`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Lab results edited successfully');
                setOpenDialog(false)
                setIsSubmitted('resolved');
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }

    };

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false)
    }


    const {patient_number, patient_name, dob, gender,result_type,
        sample_no, sample_description, lab_test, drawn_date, drawn_by} = patient;

    const {date, report,observations} = resultState;


    const reactive = [{value: 3, label: 'Reactive'}, {value: 2, label: 'Non-reactive'}];
    const blood_g = [
        {label: "A+", value: "A+"},
        {label: "A-", value: "A-"},
        {label: "B+", value: "B+"},
        {label: "B-", value: "B-"},
        {label: "O+", value: "O+"},
        {label: "O-", value: "O-"},
        {label: "AB+", value: "AB+"},
        {label: "AB-", value: "AB-"},
    ]
    const positive = [{value: 1, label: 'Positive'}, {value: 0, label: 'Negative'}];
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <CustomDialog title='Edit Results' open={openDialog} handleClose={handleCloseDialog} maxWidth="md"  isContentOverflow={false}>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <form autoComplete="off" onSubmit={handleSubmitResult}>
                <div className="lab-patient-details mb-3">
                    <div className="row col-md-12">
                        <div className="col-md-4">
                            <table className="table-sm table-borderless table prescription-table">
                                <tr>
                                    <td><p>Patient ID: <span className="ml-2"><strong>{patient_number}</strong></span>
                                    </p></td>

                                </tr>
                                <tr>
                                    <td><p>Patient Name: <span className="ml-2"><strong>{patient_name}</strong></span>
                                    </p></td>

                                </tr>
                                <tr>
                                    <td><p>Age: <span className="ml-2"><strong>{dob}</strong></span></p></td>

                                </tr>
                                <tr>
                                    <td><p>Gender: <span
                                        className="ml-2"><strong>{gender ? titleCase(gender) : ''}</strong></span></p>
                                    </td>

                                </tr>
                            </table>

                        </div>
                        <div className="col-md-4">
                            <table className="table-sm table-borderless table prescription-table">
                                <tr>
                                    <td><p>Sample No: <span className="ml-2"><strong>{sample_no}</strong></span></p>
                                    </td>

                                </tr>
                                <tr>
                                    <td><p>Sample Description: <span
                                        className="ml-2"><strong>{sample_description}</strong></span></p></td>

                                </tr>
                                <tr>
                                    <td><p>Lab Test: <span className="ml-2"><strong>{lab_test}</strong></span></p></td>

                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4">
                            <table className="table-sm table-borderless table prescription-table">
                                <tr>
                                    <td><p>Drawn Date: <span
                                        className="ml-2"><strong>{drawn_date ? formatDateTime(drawn_date) : ''}</strong></span>
                                    </p></td>

                                </tr>
                                <tr>
                                    <td><p>Drawn By: <span className="ml-2"><strong>{drawn_by}</strong></span></p></td>

                                </tr>
                                <tr>
                                    <td><p>Result Type: <span
                                        className="ml-2"><strong>{result_type === 1 ? 'Qualitative' : result_type === 2 ? 'Quantitative' : 'Notes'}</strong></span>
                                    </p></td>

                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Technician" htmlFor='technician'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type="text" disabled={true} id='technician' value={titleCase(technician)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Comments" htmlFor='comments'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <ResizableTextarea rows={2} placeholder="Write comments..."
                                                   onChange={handleChangeResultState} id="comments" name="report"
                                                   value={report}/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Date & Time"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField onChange={handleChangeResultState} name="date"
                                           value={date} type="datetime-local"/>
                            </Col>
                        </Form.Group>
                        {result_type === 1 ? <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Result" htmlFor='result'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect submitted={submitted} dataTest="result_type" id='result' value={result}
                                              onChange={handleChangeQualitative}
                                              options={q_type === '+ve/-ve' ? positive :
                                                  q_type === 'blood_group' ? blood_g : q_type === 'reactive/non-reactive' ?
                                                      reactive : []}/>
                                {(submitted && !result) && <ErrorMessage>Result is required</ErrorMessage>}
                            </Col>
                        </Form.Group> : result_type === 3 &&
                            <>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                        <Label name="Observations" htmlFor='observations'/>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <ResizableTextarea onChange={handleChangeResultState} name="observations"
                                                           value={observations} labelName="Observations"
                                                           submitted={submitted} id="observations"/>
                                        {(submitted && !observations) &&
                                            <ErrorMessage>Observation(s) is required</ErrorMessage>}
                                    </Col>
                                </Form.Group>
                            </>}
                    </div>
                </div>
                {result_type === 2 && <table className="table table-sm table-borderless">
                    <tr style={{borderTop: "1px solid #909090"}}>
                        <td width="70" className={palette.type === 'dark' && 'dark-theme-color'}>
                            <strong>Parameter</strong></td>
                        <td width="30" align="center" className={palette.type === 'dark' && 'dark-theme-color'}><strong>Result<span
                            className="help-block">*</span></strong></td>
                        <td width="30" align="center" className={palette.type === 'dark' && 'dark-theme-color'}><strong>Normal
                            Range</strong></td>
                        <td width="80" align="center" className={palette.type === 'dark' && 'dark-theme-color'}>
                            <strong>Unit</strong></td>

                    </tr>
                    {parameters.map((item, index) => (
                        <tr key={index} style={{borderTop: "1px solid #909090",}}>
                            <td width="30"> {item.parameter_name}
                            </td>
                            <td width="30" align="center"><TextField submitted={submitted} type="text"
                                                                     value={item.result}
                                                                     onChange={e => handleChangeResult(e, index)}
                                                                     className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                                {(submitted && !item.result) && <ErrorMessage>Result is required</ErrorMessage>}
                            </td>
                            <td className={palette.type === 'dark' && 'dark-theme-color'}
                                align="center">{(item.min_range && item.max_range) ?
                                `${item.min_range} - ${item.max_range}` : 'NA'} </td>
                            <td align="center"
                                className={palette.type === 'dark' && 'dark-theme-color'}>{item.unit}</td>
                        </tr>
                    ))}


                </table>}
                <button type="submit" id="submit" disabled={isFetching}
                        className="btn btn-sm sms-btn px-4">{isFetching ? "Saving..." : "Save"}
                </button>
            </form>
        </CustomDialog>

    )
};


export default EditLabResults;
