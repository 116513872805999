import React, {useState} from "react";
import {DataCell, ModuleTable} from "../../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {titleCase} from "../../../Users/addUser";
import { AutoCompleteUnControlled } from "../../../Utils/FormInputs/AutoCompleteInput";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon, StyledCell} from "../../doctorStyles";
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "../../../Utils/FormInputs/TextField";
import {BlockNavigationComponent} from "../../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../../Helpers/history";
import {ConfirmationDialog} from "../../../Utils/ReusableComponents/ConfirmationDialog";
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";


const headData = [{item:'Lab test', toolTip:'Make Lab test request here', isRequired:true},{item:'Lab', isRequired:true},
    {item:'Cost'}, {item:'Status'}, {item:'Action', isRequired:false}]


const AddLabRequest = ({actions, visit_id,isSubmitted, setIsSubmitted, setOpenDialog,
                              isBlocking, setIsBlocking,isBlockDialog, setIsBlockDialog}) =>{


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const initialState = [{
        hospital_test_id: '', test_name: '', hospital_test_type: '', service_id: '', qty: 1,
        showTest: false, isError: false, cost: 0, currency:'', currency_symbol:'',  uuid: uuidv4(),
    }]

    const [submitted, setSubmitted] = useState(false);
    const [tests, setTests] = useState([]);
    const [labTests, setLabTests] = useState(initialState);
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [test_id, setTestId] = useState('')

    const retrieveTests = (eventTxt, idx) => {
        const arr = labTests.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('hospital_test_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_tests`, formData).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        if (dt[0] === "No Labrequests Found") {
                            setTests([{value: '', label: 'No Lab tests found'}]);
                        } else {
                            setTests(dt);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, test_name: eventTxt, showTest: true, isError: false}
                }
                return {...item, test_name: eventTxt, showTest: false, isError: false}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };

    const getTestDetails = (event, idx) => {
        let arr = labTests.map((item, index)=>{
            if(index === idx){
                return {...item, test_name:event.innerText, hospital_test_id:event.value, isError: false, showTest: false}
            }
            return item
        })
        if(event.value){
            const formData = new FormData();
            formData.append('hospital_test_id', event.value);
            axios.post(`${config.smsUrl}/cdoctor/get_testdetails_by_testid`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id:+visit_id, item_id:data.service_id, item_type:'service'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if(index === idx){
                            return {...item, specimen:data.specimen ? data.specimen : '', hospital_test_type: data.hospital_test_type ? data.hospital_test_type : '',
                                service_id:data.service_id ? data.service_id : '', cost:  dataObj.cost ?? 0,is_external:data.is_external,
                                currency_id: dataObj.currency_id, currency_symbol: dataObj.currency, ...dataObj}
                        }
                        return item
                    })
                    setLabTests(arr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setTestId(data.hospital_test_id)
                        setOpenConfirmation(true)
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        setIsBlocking(true)
    };

    const addItemsToReceipt = () =>{
        setOpenConfirmation(false)
    }

    const handleCloseConfirmation = () =>{
        const arr = labTests.filter(item=>item.hospital_test_id !== test_id)
        setLabTests(arr)
        setOpenConfirmation(false)
    }

    const handleCancelConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleAddRow = () => {
        const row = {
            hospital_test_id: '', test_name: '', hospital_test_type: '', service_id: '', qty: 1,
            showTest: false, isError: false, cost: 0,uuid: uuidv4(),
        }
        setLabTests([...labTests, row])
    };

    const removeRow = (item_uuid) => {
        const arr = labTests.filter((e) => e.uuid !== item_uuid)
        setLabTests(arr);
    };


    const handleSubmitLabRequest = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const params = {
            visit_id: +visit_id,
            user_roles_id,
            lab_state:1,
            result_type: 2,
            bill_type: 2,
            bill_details: labTests.filter(test => test.hospital_test_id && test.service_id).map(item => ({
                hospital_test_id: item.hospital_test_id,
                service_id: item.service_id,
                quantity: 1,
                rate: item.cost,
                is_provided: item.is_provided,
                is_provider: item.is_provider,
                currency_id:item.currency_id
            }))
        }
        const arr = labTests.every(test => test.hospital_test_id && test.service_id)
        setIsBlocking(false)
        if (arr) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cdoctor/save_labrequest`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Lab test(s) requested successfully');
                setIsSubmitted('resolved');
                setSubmitted(false);
                setOpenDialog(false)
                setLabTests(initialState)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false);
        setSubmitted(false);
        const arr = labTests.map(item=>({
            ...item, hospital_test_id: '', test_name: '', hospital_test_type: '', service_id: '', qty: 1,
            showTest: false, isError: false, cost: 0

        }))
        setLabTests(arr)
    }

    const isPending = isSubmitted === 'pending'
    return (
        <div>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation} title='Make cash payment'
                                confirmItems={addItemsToReceipt} removeItems={handleCloseConfirmation} service='lab test'/>
            <form onSubmit={handleSubmitLabRequest}>
                <ModuleTable headData={headData}>
                    {labTests.map((test, index) => (
                        <TableRow
                            key={test.uuid}
                        >
                            <DataCell variant='200'>
                                <AutoCompleteUnControlled options={tests} submitted={submitted} idx={index}
                                                          handleChange={retrieveTests} label_text="label"
                                                          medicine_value={{label: test.test_name, value: test.test_id}}
                                                          id="test_name" value_text="value"
                                                          valid_error_check={test.isError}
                                                          errorMsg_Valid="Please a select a valid lab test"
                                                          testId={`test_name-${index}`}
                                                          handleProductData={getTestDetails} placeholder="Type Lab Test"
                                                          errorMsg="Lab test is required"/>
                            </DataCell>
                            <DataCell>
                                {titleCase(test.hospital_test_type)}
                            </DataCell>
                            <DataCell variant='150'>
                                <InputGroup>
                                    <InputGroup.Text style={{borderRadius: '0.7rem 0 0 0.7rem'}}
                                                     id="basic-addon1">{test.currency_symbol}</InputGroup.Text>
                                    <TextField type="number" value={test.cost} style={{pointerEvents: 'none'}}/>
                                </InputGroup>
                            </DataCell>
                            <DataCell>
                                {test.is_external === true ? 'External Test' : test.is_external === false ? 'Internal Test' : ''}
                            </DataCell>
                            <DataCell>
                                <Tooltip title='Remove'>
                                    <IconButton type='button' onClick={() => removeRow(test.uuid)}>
                                        <StyledRemoveIcon/>
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <StyledCell colSpan={headData.length}>
                            <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                    id="add-row">Add another line
                            </button>
                        </StyledCell>
                    </TableRow>

                </ModuleTable>
                <button type='submit' className='btn sms-btn btn-sm'
                        disabled={isBlockDialog}>{isPending ? 'Saving...' : 'Save Lab Request'}</button>

            </form>

        </div>

    )
}

export {AddLabRequest}



