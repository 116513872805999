/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { formatDateTime } from "../Utils/ConvertDate";
import { titleCase } from "../Users/addUser";
import { detailsTable, table, textWeight } from "../../styles/tableStyles";
import { ItemText } from "../Utils/Lists/ReportList";
import { ItemsTable, PrintTemplate } from "../Utils/Templates/PrintTemplate";
import { splitNotes } from "../Radiology/RadiologyReport";
import * as colors from "../../styles/colors";
import axios from "axios";
import { config } from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";

const tdComments = css`
  border-bottom: 1px solid ${colors.text01};
`;

const approvalTable = css`
  margin-top: 3rem;
`;
const approvalSpan = css`
  border-bottom: 1px solid ${colors.text01};
  display: inline-block;
`;

const headData = [
    { key: "parameter", value: "Parameter" },
    { key: "result", value: "Result" }, //{key:'flag',value:'flag'},
    { key: "unit", value: "Unit" },
    { key: "ref_range", value: "Reference Range" },
];

const noneRefData = [
    { key: "parameter", value: "Parameter" },
    { key: "result", value: "Result" },
];
const ResultsReportComponent = ({ test_results_id, actions}) => {
    const [resultDetails, setResultDetails] = useState({});
    const [params, setParams] = useState([]);

    useEffect(() => {
        if (!test_results_id) {
            return;
        }
        const formData = new FormData();
        formData.append("test_results_id", test_results_id);
        axios
            .post(
                `${config.smsUrl}/claboratory/get_lab_results_details_by_id`,
                formData
            )
            .then((res) => {
                const data = res.data;
                const dt = !data ? {} : data;
                const patient_info = !dt.patient_info ? {} : dt.patient_info;
                const parameters = !dt.params ? [] : dt.params;
                setResultDetails({
                    ...patient_info,
                    visit_id: parameters[0]?.visit_id,
                    approved_at: parameters[0]?.approved_at,
                    date_recorded:parameters[0]?.date_created
                });
                setParams(parameters);
            })
            .catch((err) => {
                logoutErrorMessage(err, null, actions);
            });
    }, [test_results_id]);

    const name = `${resultDetails.lab_first_name ? resultDetails.lab_first_name : ""} ${resultDetails.lab_last_name ? resultDetails.lab_last_name : ""}`;
    const patient_name = `${!resultDetails.patient_firstname ? "" : resultDetails.patient_firstname} ${
        !resultDetails.patient_lastname ? "" : resultDetails.patient_lastname
    }`;
    const date = resultDetails.time_requested ? formatDateTime(resultDetails.time_requested) : "";
    const approved_at_date = resultDetails.approved_at ? formatDateTime(resultDetails.approved_at) : "";

    const date_recorded = resultDetails.date_recorded ? formatDateTime(resultDetails.date_recorded) : ''
    const doctor_name = `${!resultDetails.doctor_first_name ? "" : resultDetails.doctor_first_name} ${
        !resultDetails.doctor_last_name ? "" : resultDetails.doctor_last_name
    }`;
    const approvedBy = `${resultDetails.approved_by_first_name ? resultDetails.approved_by_first_name : ""} ${
        resultDetails.approved_by_last_name ? resultDetails.approved_by_last_name : ""}`;

    const [visitDetails, setVisitDetails] = useState({});

    useEffect(() => {
        if (!resultDetails.visit_id) {
            return;
        }
        axios
            .get(`${config.smsUrl}/cvisit/${resultDetails.visit_id}`)
            .then((res) => {
                const data = res.data;
                const dt = !data ? {} : data;
                setVisitDetails(dt);
            })
            .catch((err) => {});
    }, [resultDetails.visit_id]);

    const isReferenceRange = params.some((item) => item.result_type === 2);
    const patientDetails = (
        <tbody>
        <tr>
            <td>
                <span>Patient Name: </span>{" "}
                <span css={[textWeight]}>{patient_name}</span>
            </td>
            <td>
                <span>Patient No.: </span>{" "}
                <span css={[textWeight]}>{resultDetails.patient_number}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span>Gender: </span> <span css={[textWeight]}>{resultDetails.gender}</span>
            </td>
            <td>
                <span>Date requested:</span> <span css={[textWeight]}>{date}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span>Address: </span> <span css={[textWeight]}>{resultDetails.address}</span>
            </td>
            <td>
                <span>Lab Technician:</span> <span css={[textWeight]}>{name}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span>Age: </span>{" "}
                <span>
            <strong>{resultDetails.age}</strong>
          </span>
            </td>
            <td>
                <span>Requested by: </span>{" "}
                <span css={[textWeight]}>{doctor_name}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span>Payment Mode: </span>{" "}
                <span css={[textWeight]}>
            {visitDetails.is_on_insurance === 1
                ? "CREDIT"
                : visitDetails.is_on_insurance === 0
                    ? "CASH"
                    : ""}
          </span>
            </td>
            {visitDetails.is_on_insurance === 1 ? (
                <td>
                    <span>Provider Name: </span>{" "}
                    <span css={[textWeight]}>
              {visitDetails?.insurance_provider_name}
            </span>
                </td>
            ) : (
                <td />
            )}
        </tr>
        <tr>
            {visitDetails.is_on_insurance === 1 ? (
                <td>
                    <span>Account Name: </span>{" "}
                    <span css={[textWeight]}>{visitDetails?.account_name}</span>
                </td>
            ) : (
                <td />
            )}
            <td />
        </tr>

        <tr>
            <td>
                <span>Date recorded: </span>{" "}
                <span css={[textWeight]}>{date_recorded}</span>
            </td>
            <td>
                <span>Approved on: </span>{" "}
                <span css={[textWeight]}>{approved_at_date}</span>
            </td>
        </tr>
        </tbody>
    );
    const details = (
        <>
            <ItemText data-testid="lab_result_name">{resultDetails.hospital_test_name}</ItemText>
            <ItemsTable
                headData={isReferenceRange ? headData : noneRefData}
                testId="lab-results-details"
            >
                <tbody>
                {params.map((test) => {
                    return (
                        <tr key={test.hospital_test_id}>
                            <td>
                  <span>
                    {test.result_type === 2
                        ? (test.sub_test_name ?  titleCase(test.sub_test_name) : "Result")
                        : test.result_type === 1 &&
                        test.sub_test_name === "blood_group"
                            ? "Blood Group"
                            : test.result_type === 1
                                ? "Result"
                                : "Observations"}
                  </span>
                            </td>
                            {test.result_type === 1 ? (
                                <td>
                                    {test.sub_test_name === "+ve/-ve" ? (
                                        <span>
                        {test.test_value === "1" ? "Positive" : "Negative"}
                      </span>
                                    ) : test.sub_test_name === "reactive/non-reactive" ? (
                                        <span>
                        {test.test_value === "3" ? "Reactive" : "Non-reactive"}
                      </span>
                                    ) : test.sub_test_name === "blood_group" ? (
                                        <span>{test.test_value}</span>
                                    ) : (
                                        <p />
                                    )}
                                </td>
                            ) : (
                                <td colSpan={test.result_type === 3 ? 4 : 0}>
                    <span>
                      {test.result_type === 3
                          ? splitNotes(test.test_value)
                          : test.test_value}
                    </span>
                                </td>
                            )}
                            {isReferenceRange ? (
                                <td>
                                    <span>{test.unit ? test.unit : "NA"}</span>
                                </td>
                            ) : null}
                            {isReferenceRange ? (
                                <td>
                    <span>
                      {test.reference_range ? test.reference_range : "NA"}
                    </span>
                                </td>
                            ) : null}
                        </tr>
                    );
                })}
                {resultDetails.notes ? (
                    <tr>
                        <td
                            colSpan={
                                isReferenceRange ? headData.length : noneRefData.length
                            }
                            css={[textWeight]}
                            style={{ paddingBottom: 0 }}
                        >
                            <span>Comments</span>
                        </td>
                    </tr>
                ) : null}
                {resultDetails.notes ? (
                    <tr>
                        <td colSpan={headData.length} css={[tdComments]}>
                            <span>{resultDetails.notes ? splitNotes(resultDetails.notes) : resultDetails.notes}</span>
                        </td>
                    </tr>
                ) : null}
                </tbody>
            </ItemsTable>
        </>
    );

    const approval = (
        <table
            css={[table, detailsTable, approvalTable]}
            className="lab-results-approval"
        >
            <tbody>
            <tr>
                <td>
                    <span>Carried Out By </span>
                </td>
                <td />
                <td align="right">
                    <span>Approved By</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span css={[approvalSpan]}>{name}</span>
                </td>
                <td />
                <td align="right">
                    <span css={[approvalSpan]}>{approvedBy}</span>
                </td>
            </tr>
            </tbody>
        </table>
    );

    const itemDetails = (
        <>
            {details}
            {approval}
        </>
    );

    return (
        <div>
            <PrintTemplate title="Lab Results" {...{ patientDetails, itemDetails }} />
        </div>
    );
};
export { ResultsReportComponent };
